
export default function findConsecutives(array, sequentialDidQuantity) {
    // sort in increasing order 
    const sortedArr = array.sort((a,b)=> a-b);

    if (sequentialDidQuantity) {
      const dataToDisplay = [];
      let consecutivesNumbers = [];
  
      for (let i = 0; i<sortedArr.length; i++) {
    
        // if numbers are consecutive add the first one to the consecutivesNumbers array 
          // NOTE that the last consequtive number in a range won't be added to the array, which will be handled in the else clause 
          if (sortedArr[i+1] - sortedArr[i] === 1) {
          consecutivesNumbers.push(sortedArr[i]);
        } else {
    
          // if numbers are not consecutive check if the first one was the last consecutive number in the previous range or it is a single number
          if (sortedArr[i] - consecutivesNumbers[consecutivesNumbers.length-1] === 1) {
            consecutivesNumbers.push(sortedArr[i]);
          } else {
            dataToDisplay.push([sortedArr[i]]);
          }

          if (consecutivesNumbers.length) {
            dataToDisplay.push([...consecutivesNumbers])
            consecutivesNumbers = [];
          }
        }
      }
    
      const dataToDisplaySequential = [];
      const quant = Number(sequentialDidQuantity);
  
      for (let i = 0; i<dataToDisplay.length; i++) {

        // If the length of consequtive numbers is greater than max consequitve number provided by the user
            // divide the consequtive numbers into arrays of length equal to the max consequitve number (or less)
        
        if (dataToDisplay[i].length > quant) {
          for (let j=0; j < dataToDisplay[i].length; j=j+quant) {
            dataToDisplaySequential.push(dataToDisplay[i].slice(j, j+quant));
          }
        } else {
          dataToDisplaySequential.push(dataToDisplay[i]);
        }
      }

      // sort based on array length
      dataToDisplaySequential.sort((a,b)=>b.length - a.length);

      //  DISABLE RANGE
      const dataToDisplaySequentialNoRange = [];
      dataToDisplaySequential.forEach(item => {
        item.forEach(subitem => {
          if (dataToDisplaySequentialNoRange.length < 200) {
            dataToDisplaySequentialNoRange.push([subitem])
          }
        })
      })
      return dataToDisplaySequentialNoRange;

      // return dataToDisplaySequential;

    } else {

      const dataToDisplay = sortedArr.map(item => [item])
      return dataToDisplay;
      
    }
  }