import {useState} from "react";
import {useValidateZipCodeMutation} from '../../services/requests';

function useValidateCustomerData() {
    const [fieldValidity, setFieldValidity] = useState({validZip: true, validPhoneNumber: true, validEmail: true});
    const [validateZipCodeRequest, validateZipCodeResponse] = useValidateZipCodeMutation();
    
    const isValidZip = async (value, country, state) => {
        const regexp = country === "USA" ?
            /(^\d{5}$)|(^\d{5}-\d{4}$)/ :
            /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/
        const isValidZipFormat = regexp.test(value);
        let isValidZipCode = false;
        if (isValidZipFormat) {
            const data = {
                zipCode: value,
                country: country === "USA" ? "United States" : "Canada",
                administrativeArea: state
            }
            const resp = await validateZipCodeRequest(data).unwrap();
            if (resp === "valid") {
                isValidZipCode = true;
            }
        }
        return isValidZipCode;
    }

    const validateCustomerData = async (customerInfo) => {
        const validZip = await isValidZip(customerInfo.zip, customerInfo.country, customerInfo.state);
        const validPhoneNumber = customerInfo.phoneNumber.length === 10;
        const emailPattern = /^[^.][a-zA-Z0-9_.-]+[^.]@[^-.][a-zA-Z0-9-]+[^-.]\.[^-.][a-zA-Z0-9-.]+[^-.]$/;
        const validEmail = emailPattern.test(customerInfo.emailAddress);
        setFieldValidity({validZip, validPhoneNumber, validEmail});
        if (validZip && validPhoneNumber && validEmail) {
            return true;
        } 
        return false;
    }
    
    return {
        validateCustomerData,
        setFieldValidity,
        fieldValidity,
        validationInprogress: validateZipCodeResponse.isLoading
    };
}

export default useValidateCustomerData;