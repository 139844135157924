import { graphConfig } from "../config/auth_config";

export async function getUserRoles(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
        method: "GET",
        headers: headers
    };
  
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => Promise.reject(error));
}