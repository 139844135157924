import React from 'react';
import {Box, Link, Paper, Typography} from "@mui/material";
import {StyledPrimaryButton, StyledSecondaryButton} from "../styles/mui_styles";


function LeadAlreadyProcessed() {
    return (
        <Box className="dialog_block">
            <Box className="paper_block">
                <Box component="svg" className="warning_img"/>
                <Typography component="h2" className="paper_title">
                    Phone Numbers Already Chosen
                </Typography>
                <Typography component="span" className="paper_subtitle">
                    You have already finished this step. If you need assistance,
                </Typography> <Typography component="span" className="paper_subtitle">
                simply click the Contact button below.
                </Typography>
            </Box>
            <Box className="paper_button_box">
                <StyledSecondaryButton component={Link} target="_blank"
                                       href="https://www.bluip.com/company/contact">Contact</StyledSecondaryButton>
                <StyledPrimaryButton component={Link} target="_blank" href="https://admin.teams.microsoft.com/">Admin
                    Center</StyledPrimaryButton>
            </Box>
        </Box>
    )
}

export default LeadAlreadyProcessed
