import React from 'react';
import {Box, Paper, Typography, Link} from "@mui/material";
import {StyledSecondaryButton, StyledPrimaryButton} from "../styles/mui_styles";

function NotFound() {
    return (
        <Box className="dialog_block">
            <Box className="paper_block">
                <Box component="svg" className="warning_img"/>
                <Typography component="h2" className="paper_title">
                    Missing Tenant ID
                </Typography>
                <Typography component="span" className="paper_subtitle">
                    Oops! We ran into an issue logging you in.
                </Typography>
                <Typography component="span" className="paper_subtitle">
                    Please contact our Support Team.
                </Typography>
            </Box>
            <Box className="paper_button_box">
                <StyledSecondaryButton component={Link} target="_blank"
                                       href="https://www.bluip.com/company/contact">Contact</StyledSecondaryButton>
                <StyledPrimaryButton component={Link} target="_blank" href="https://admin.teams.microsoft.com/">Admin
                    Center</StyledPrimaryButton>
            </Box>
        </Box>
    )
}

export default NotFound
