import React, {useState} from 'react';
import {Box, IconButton, InputAdornment, Typography, SvgIcon,InputLabel} from "@mui/material";
import {StyledButton, StyledDatePicker, StyledCalendarIconButton, StyledPickersLayout} from "../../styles/mui_styles";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';


function DateRangePicker({appliedFromValue, appliedToValue, handleFiltersStateChange}) {

    const [fromValue, setFromValue] = useState(appliedFromValue ? dayjs(appliedFromValue) : null);
    const [toValue, setToValue] = useState(appliedToValue ? dayjs(appliedToValue) : null);
    const [error, setError] = useState(false);
    const [fromOpen, setFromOpen] = useState(false);
    const [toOpen, setToOpen] = useState(false);

    const handleApply = () => {
        handleFiltersStateChange("acquiredOn", {from: dayjs(fromValue).valueOf(), to: dayjs(toValue).valueOf()});
    }

    const handleClear = () => {
        setFromValue(null);
        setToValue(null);
        if (error) {
            setError(false);
        }
    }


    return (
        <Box className="custom_calendar_block">
            <Box className='calendar_block'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <InputLabel label="From" className='calendar_block--label'>From</InputLabel>
                        <StyledDatePicker
                            open={fromOpen}
                            onOpen={() => setFromOpen(true)}
                            onClose={() => setFromOpen(false)}
                            onAccept={() => setFromOpen(false)}
                            sx={{marginBottom: 1.5}}
                            value={fromValue}
                            defaultValue={dayjs(new Date())}
                            onChange={(newValue) => {
                                if (toValue && dayjs(newValue).isAfter(toValue)) {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                                setFromValue(newValue);
                            }}
                            slotProps={{
                                field: {readOnly: true},
                                textField: {
                                    onClick: () => setFromOpen(true),
                                    inputProps: {style: {visibility: fromValue ? "visible" : "hidden"}, placeholder: ""},
                                    sx: (theme) => ({
                                        
                                    }),
                                },
                                day: {
                                    sx: (theme) => ({
                                        '&.MuiPickersDay-root.Mui-selected': {
                                            backgroundColor: "#4A85FF",
                                        },
                                        '&.MuiPickersDay-root': {
                                            '&:hover': {
                                                backgroundColor: "#EDF3FF",
                                            },
                                        },
                                        '&.MuiPickersYear-root.Mui-selected': {
                                            backgroundColor: "#4A85FF",
                                            '&:hover': {
                                                backgroundColor: "#EDF3FF",
                                            },
                                            '&:focus': {
                                                backgroundColor: "#4A85FF",
                                            },
                                        },
                                    }),
                                }
                            }}

                            slots={{
                                layout: StyledPickersLayout,
                                inputAdornment: (props) => {
                                    const {children, ...other} = props;
                                    return <InputAdornment {...other}>
                                        {
                                            fromValue ?
                                                <StyledCalendarIconButton
                                                    aria-label="clear date"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFromValue(null);
                                                        if (error) {
                                                            setError(false)
                                                        }
                                                    }}
                                                    size='small'
                                                >
                                                    <SvgIcon className="clear_icon"/>
                                                </StyledCalendarIconButton> :
                                                <StyledCalendarIconButton {...other}>
                                                    <SvgIcon className="calendar_icon"/>
                                                </StyledCalendarIconButton>
                                        }
                                    </InputAdornment>
                                }
                            }}
                        />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <InputLabel label="To" className='calendar_block--label'>To </InputLabel>
                        <StyledDatePicker
                            open={toOpen}
                            onOpen={() => setToOpen(true)}
                            onClose={() => setToOpen(false)}
                            onAccept={() => setToOpen(false)}
                            value={toValue}
                            onChange={(newValue) => {
                                if (fromValue && dayjs(newValue).isBefore(fromValue)) {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                                setToValue(newValue);
                            }}
                            slotProps={{
                                field: {readOnly: true},
                                textField: {
                                    onClick: () => setToOpen(true),
                                    inputProps: {style: {visibility: toValue ? "visible" : "hidden"}, placeholder: ""}
                                }
                            }}
                            slots={{
                                layout: StyledPickersLayout,
                                inputAdornment: (props) => {
                                    const {children, ...other} = props;
                                    return <InputAdornment {...other}>
                                        {
                                            toValue ?
                                                <StyledCalendarIconButton
                                                    aria-label="clear date"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setToValue(null);
                                                        if (error) {
                                                            setError(false)
                                                        }
                                                    }}
                                                    size='small'
                                                >
                                                    <SvgIcon className="clear_icon"/>
                                                </StyledCalendarIconButton> :
                                                <StyledCalendarIconButton {...other}>
                                                    <SvgIcon className="calendar_icon"/>
                                                </StyledCalendarIconButton>
                                        }
                                    </InputAdornment>
                                }
                            }}
                        />

                </LocalizationProvider>

                {
                    error &&
                    <Typography className="calendar_block--error">Please select a To: date that occurs after the From: date</Typography>
                }
            </Box>
            <Box className='button_block'>
                <StyledButton className='secondary' size='small' onClick={handleClear}>
                    Clear
                </StyledButton>
                <StyledButton className='primary' size='small' onClick={handleApply}
                              disabled={(!fromValue && !toValue) || error}
                >
                    Apply
                </StyledButton>
            </Box>
        </Box>
)
}

export default DateRangePicker
