const { REACT_APP_LOGIN_REDIRECT_URI, REACT_APP_CLIENT_ID, REACT_APP_SCOPES, REACT_APP_ROLE_SCOPE} = process.env;

export const msalConfig = {
    auth: {
        clientId: REACT_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: REACT_APP_LOGIN_REDIRECT_URI,
        postLogoutRedirectUri:  REACT_APP_LOGIN_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        // storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        //allowRedirectInIframe: true
        allowNativeBroker: false
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["Directory.Read.All"],
    // extraScopesToConsent: ["api://c08d789e-b96d-4373-b69a-61f41880cc23/User.Read"],
    redirectStartPage: `${REACT_APP_LOGIN_REDIRECT_URI}/login`
};

export const tokenRequestGraph = {
    scopes: ["Directory.Read.All"],
};

export const tokenRequestAPI = {
    scopes: [REACT_APP_ROLE_SCOPE],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
};