import {Grid, MenuItem, Box, InputAdornment, Typography} from "@mui/material";
import {
    StyledContactTextField,
    StyledContactSelect,
    StyledContactInputLabel,
    StyledContactFormControl,
    StyledWarningFormHelperText, StyledCalendarTextField,
} from "../../styles/mui_styles";
import {countries_and_states} from "../../assets/countries_and_states";
import React from "react";

function ProvideContactInfo({customerInfo, setCustomerInfo, fieldValidity, setFieldValidity}) {
    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === "firstName" || name === "lastName") {
            value = value.replace(/([^\w! -]+)/g, "").slice(0, 29);
        }
        if (name === "phoneNumber") {
            value = value.replace(/\D/g, "").slice(0, 10);
            setFieldValidity(prev => ({...prev, validPhoneNumber: true}));
        }
        setCustomerInfo(prev => ({...prev, [name]: value}));

        if (name === "emailAddress") {
            setFieldValidity(prev => ({...prev, validEmail: true}));
        }
        if (name === "zip" || name === "state") {
            setFieldValidity(prev => ({...prev, validZip: true}));
        }
        if (name === "country") {
            setCustomerInfo(prev => ({...prev, address1: "", address2: "", city: "", state: "", zip: ""}));
            setFieldValidity(prev => ({...prev, validZip: true}));
        }
    }

    return (
        <Box className="step_content">
            <Box className="form_title">
                <Typography className="form_title--step-2">
                    Fill in your contact information below.
                    We will use the information below to communicate with you.
                </Typography>
            </Box>
            <Grid container sx={{justifyContent:"space-between"}} spacing={2} mb={2}>
                <Grid item xs={5.8} id="first_name">
                    <StyledContactTextField
                     autoComplete="new-name"
                     inputProps={{
                        form: {
                          autocomplete: 'off',
                        },
                        className: customerInfo.firstName && "filled"
                      }}
                        label="First Name"
                        name="firstName"
                        value={customerInfo.firstName}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className={`customer_info_input`}
                    />
                </Grid>
                <Grid item xs={5.8} id="last_name">
                    <StyledContactTextField
                     autoComplete="new-name"
                     inputProps={{
                        form: {
                          autocomplete: 'off',
                        },
                        className: customerInfo.lastName && "filled"
                      }}
                        label="Last Name"
                        name="lastName"
                        value={customerInfo.lastName}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                    />
                </Grid>
                <Grid item xs={5.8} id="phone_number">
                    <StyledContactTextField
                      autoComplete="new-name"
                      inputProps={{
                         form: {
                           autocomplete: 'off',
                         },
                         className: customerInfo.phoneNumber && fieldValidity.validPhoneNumber && "filled"
                       }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">+1</InputAdornment>),
                            className: customerInfo.phoneNumber && fieldValidity.validPhoneNumber  && "filled"
                        }}
                        label="Phone Number"
                        name="phoneNumber"
                        value={customerInfo.phoneNumber}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                        error={!fieldValidity.validPhoneNumber}
                    />
                    <StyledWarningFormHelperText
                        sx={{visibility: !fieldValidity.validPhoneNumber ? "visible" : "hidden"}} error>
                        Please enter a valid number
                    </StyledWarningFormHelperText>
                </Grid>
                <Grid item xs={5.8} id="email_address">
                    <StyledContactTextField
                     autoComplete="new-name"
                     inputProps={{
                        form: {
                          autocomplete: 'off',
                        },
                        className: customerInfo.emailAddress && fieldValidity.validEmail && "filled"
                      }}
                        label="Email Address"
                        name="emailAddress"
                        value={customerInfo.emailAddress}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                        error={!fieldValidity.validEmail}
                    />
                    <StyledWarningFormHelperText sx={{visibility: !fieldValidity.validEmail ? "visible" : "hidden"}}
                                                 error>
                        Please enter a valid email
                    </StyledWarningFormHelperText>
                </Grid>
                <Grid item xs={5.8} id="address1">
                    <StyledContactTextField
                       autoComplete="new-name"
                       inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                          className: customerInfo.address1 && "filled"
                        }}
                        label="Address 1"
                        name="address1"
                        value={customerInfo.address1}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                    />
                </Grid>
                <Grid item xs={5.8} id="address2">
                    <StyledContactTextField
                        autoComplete="new-name"
                        inputProps={{
                           form: {
                             autocomplete: 'off',
                           },
                           className: customerInfo.address2 && "filled"
                         }}
                        label="Address 2"
                        name="address2"
                        value={customerInfo.address2}
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                    />
                </Grid>
                <Grid item xs={5.8}>
                    <StyledContactTextField
                     autoComplete="new-name"
                     inputProps={{
                        form: {
                          autocomplete: 'off',
                        },
                        className: customerInfo.city && "filled"
                      }}
                        label="City"
                        name="city"
                        value={customerInfo.city}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                    />
                </Grid>
                <Grid item xs={5.8} id="state_province">
                    <StyledContactFormControl variant="filled" required size="small" className="customer_info_input">
                        <StyledContactInputLabel id="select-state">State/Province</StyledContactInputLabel>
                        <StyledContactSelect
                          inputProps={{
                            className: customerInfo.state && "filled"
                          }}
                            autoComplete='off'
                            disableUnderline
                            labelId="select-state"
                            name="state"
                            size="Normal"
                            value={customerInfo.state}
                            onChange={(e) => handleChange(e)}
                            MenuProps={{sx: {maxHeight: 300}}}
                            sx={{borderRadius: 8, backgroundColor: '#F6F7FB'}}
                        >
                            {countries_and_states[customerInfo.country].states.map(state => {
                                return <MenuItem key={state.abbreviation}
                                                 value={state.abbreviation}>{state.name}</MenuItem>
                            })}
                        </StyledContactSelect>
                    </StyledContactFormControl>
                </Grid>
                <Grid item xs={5.8} id="postal_zip_code">
                    <StyledContactTextField
                    autoComplete="new-name"
                    inputProps={{
                       form: {
                         autocomplete: 'off',
                       },
                       className: customerInfo.zip && fieldValidity.validZip && "filled"
                     }}
                        label="Postal/ZIP code"
                        name="zip"
                        value={customerInfo.zip}
                        required
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        defaultValue="Normal"
                        className="customer_info_input"
                        error={!fieldValidity.validZip}
                    />
                    <StyledWarningFormHelperText sx={{visibility: !fieldValidity.validZip ? "visible" : "hidden"}}
                                                 error>
                        Please enter a valid postal / ZIP code
                    </StyledWarningFormHelperText>
                </Grid>
                <Grid item xs={5.8} id="country">
                    <StyledContactFormControl variant="filled" required size="small" className="customer_info_input">
                        <StyledContactInputLabel id="select-country">Country</StyledContactInputLabel>
                        <StyledContactSelect
                         inputProps={{
                            className: customerInfo.country && "filled"
                          }}
                            disableUnderline
                            labelId="select-country"
                            name="country"
                            size="Normal"
                            value={customerInfo.country}
                            onChange={(e) => handleChange(e)}
                            sx={{borderRadius: 8}}
                        >
                            <MenuItem value={"USA"}>United States</MenuItem>
                            <MenuItem value={"Canada"}>Canada</MenuItem>
                        </StyledContactSelect>
                    </StyledContactFormControl>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProvideContactInfo;
