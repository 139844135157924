
export function sortData(dir, field) {
    if (dir === 1) {
        return  field === "acquiredOn" ? sortDateAZ : sortAZ;
    } else {
        return  field === "acquiredOn" ? sortDateZA : sortZA;
    }

    function sortAZ(a, b) {
        return  b[field].localeCompare(a[field]);
    }
    
    function sortZA(a, b) {
        return  a[field].localeCompare(b[field]);
    }
    
    function sortDateAZ(a, b) {
        return  new Date(a[field]) - new Date(b[field]);
    }
    
    function sortDateZA(a, b) {
        return  new Date(b[field]) - new Date(a[field]);
    }
}

