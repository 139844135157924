import { configureStore } from "@reduxjs/toolkit";
import { requests } from './services/requests';


export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      [requests.reducerPath]: requests.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(requests.middleware),
  })
}