import { tokenRequestAPI, tokenRequestGraph } from "../config/auth_config";
import { msalInstance } from "../index";

// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react#call-the-microsoft-graph-api
//https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2


export function getAccessToken(mode, resource) {
    const account = msalInstance.getActiveAccount();
    const request = resource === "graph" ? ({ ...tokenRequestGraph, account: account}) : ({ ...tokenRequestAPI, account: account})

    if (mode === "silent") {
        return msalInstance.acquireTokenSilent(request).then((response) => {
            return response.accessToken;
        }).catch(err => {
            throw err;
        })
    } else if (mode === "popup") {
        return msalInstance.acquireTokenRedirect(request).then((response) => {
            return response.accessToken;
        })
    }
}