import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useParams} from "react-router-dom";
import ActiveLead from './components/ActiveLead';
import NotFound from './components/NotFound';
import LeadAlreadyProcessed from './components/LeadAlreadyProcessed';
import Layout from "./components/Layout";
import CustomerNumbers from './components/number_management/CustomerNumbers';
import "./styles/style.scss";
import StartTrial from './components/StartTrial';
import Login from './components/auth/Login';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser"
import AccessDenied from './components/auth/AccessDenied';



function ProtectedRoute({page}) {
  const {inProgress, instance} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const globalAdminInfo = localStorage.getItem("globalAdminInfo");
  const logisenseCustomerInfo = localStorage.getItem("logisenseInfo");
  if (!isAuthenticated && inProgress === InteractionStatus.None) {
    return <Navigate to="/login"/>
  } else if (isAuthenticated && logisenseCustomerInfo === "yes" && globalAdminInfo === "yes") {
    return <Outlet context={{tenantId: instance.getActiveAccount()?.tenantId, username: instance.getActiveAccount()?.username}}/>;
  } else {
    return null;
  }
};

const OutletContextProvider = () => {
  const { tenantId } = useParams();
  return <Outlet context={{ tenantId }} />;
};

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/login" />} /> 
            <Route element={<ProtectedRoute />}>
              <Route path="numbers" element={<CustomerNumbers />} />
            </Route>

            <Route path=":tenantId/*" element={<OutletContextProvider />}>
              <Route path="start_trial" element={<StartTrial />} />
              <Route path="active_lead" element={<ActiveLead />} />
              <Route path="lead_already_processed" element={<LeadAlreadyProcessed />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="access_denied" element={<AccessDenied />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
