import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
const { REACT_APP_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } = process.env;
import { getAccessToken } from './getAccessToken';

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BASE_URL,
  prepareHeaders: async (headers, api) => {
    if (["getCustomer", "getCustomerNumbers", "getOCCustomer", "addPhoneNumbers", "getTaskStatus", "getTasks", "deletePhoneNumbers", "getUserTasks", "raiseTaskEvent"].includes(api.endpoint)) {
      let token;
      try {
        token = await getAccessToken("silent");
      } catch (error) {
        token = await getAccessToken("popup");
      }
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      headers.set('Ocp-Apim-Subscription-Key', REACT_APP_SUBSCRIPTION_KEY)
    }
    return headers;
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const newToken = await getAccessToken("popup");
    if (newToken) {
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result;
}

export const requests = createApi({
  reducerPath: 'requestsReducer',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLeadByTenantId: builder.mutation({
      query: (params) => {
        return {
          method: 'GET',
          url: 'getleadbytenantid',
          params: params,
        }
      },
      // transformResponse: (resp) => {
      //   return {
      //     // status: "lead_processed",
      //     status: "lead_not_processed",
      //     lead_data: {
      //       umpCustomerGuid: "ee982672-d46d-407a-959d-1d9d716b5e5e",
      //       "contactDetails": {
      //         "fullName": "Anush Hambaryan",
      //         "email": "ahambaryan@bluip.com",
      //         "phoneNumber": "+17472708756",
      //         "companyName": "Default Directory",
      //         "companySize": "L25_U49"
      //     },
      //     }
      //   }
      // }
    }),
    searchPhoneNumbers: builder.mutation({
      query: (data) => {
        const {code, quantity } = data;
        return {
          method: 'GET',
          url: 'searchNumbers',
          params: { code, quantity },
        }
      },
    }),
    startPOCSetup: builder.mutation({
      query: (data) => {
        const { phoneNumbers, tenantId, customerInfo } = data;
        return {
          method: 'POST',
          url: 'buyNumbers',
          body: { phoneNumbers, tenantId, customerInfo },
        }
      },
    }),
    addPhoneNumbers: builder.mutation({
      query: (data) => {
        const { phoneNumbers, tenantId, username } = data;
        return {
          method: 'POST',
          url: 'orchestrators/AddNumbersOrchestrator',
          // url: 'http://localhost:7071/api/orchestrators/AddNumbersOrchestrator',
          body: { phoneNumbers, tenantId, username},
        }
      },
    }),
    deletePhoneNumbers: builder.mutation({
      query: (data) => {
        const { phoneNumbers, tenantId, username } = data;
        return {
          method: 'POST',
          url: 'deleteNumbers',
          // url: 'http://localhost:7071/api/DeleteNumbersHttpStart',
          body: { phoneNumbers, tenantId, username},
        }
      },
    }),
    validateZipCode: builder.mutation({
      query: (data) => {
        const { zipCode, country, administrativeArea } = data;
        return {
          method: 'POST',
          url: 'validateZipCode',
          body: { zipCode, country, administrativeArea },
        }
      }
    }),
    getCustomer: builder.mutation({
      query: (params) => {
        const { customerName } = params;
        return {
          url: 'customer',
          params: { customerName }
        }
      }
    }),
    getCustomerNumbers: builder.query({
      query: (params) => {
        const { tenantId } = params;
        return {
          url: 'numbers',
          // url: 'http://localhost:7071/api/GetNumbers',
          params: { tenantId }
        }
      },
      transformResponse: (resp) => {
        //let resp = mockData.concat(res);
        let data = resp.map((item, index) => {
          const {telephoneNumber, acquiredOn} = item;
          const isoCountryCode = item.isoCountryCode === "CA" ? "Canada" : item.isoCountryCode;
          const numberType = item.acquiredCapabilities.includes("FirstPartyAppAssignment") ? "Resource" :
             item.acquiredCapabilities.includes("UserAssignment") ? "User" :
             ""
          return {
            telephoneNumber,
            isoCountryCode,
            acquiredOn,
            numberType
          }
        });
        return data;
      }
    }),
    getOCCustomer: builder.mutation({
      query: (params) => {
        const { customerId } = params;
        return {
          url: 'oc_customer',
          params: { customerId }
        }
      }
    }),
    getTaskStatus: builder.mutation({
      query: (instanceId) => {
        return {
          url: `/checkstatus/${instanceId}`,
          // url: `http://localhost:7071/runtime/webhooks/durabletask/instances/${instanceId}`
        }
      }
    }),
    getTasks: builder.query({
      query: () => {
        return {
          url: `getTasks?runtimeStatus=Running`,
        }
      }
    }),
    getUserTasks: builder.query({
      query: (params) => {
        return {
          // url: "http://localhost:7071/api/GetUserTasks",
          url: "GetUserTasks",
          params: params,
        }
      }
    }),
    raiseTaskEvent: builder.mutation({
      query: (params) => {
        const {instanceId, event} = params;
        return {
          method: 'POST',
          url: `raiseTaskEvent/${instanceId}/${event}`,
          // url: `http://localhost:7071/runtime/webhooks/durabletask/instances/${instanceId}/raiseEvent/${event}`,
          body: {done: true, task: "raiseEvent"},
        }
      }
    })
  }),
});

export const {
  useGetLeadByTenantIdMutation,
  useSearchPhoneNumbersMutation,
  useStartPOCSetupMutation,
  useAddPhoneNumbersMutation,
  useDeletePhoneNumbersMutation,
  useValidateZipCodeMutation,
  useGetCustomerNumbersQuery,
  useGetCustomerMutation,
  useGetOCCustomerMutation,
  useGetTaskStatusMutation,
  useGetUserTasksQuery,
  useRaiseTaskEventMutation
} = requests;
