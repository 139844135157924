import { Typography, Box} from '@mui/material/';

function ConfirmNumberSelection({userNumbersQuantity, resourceNumbersQuantity, poc}) {
    const totalQuantity = userNumbersQuantity + resourceNumbersQuantity;

    return (
        <Box className="modal_box--confirm">
            <Typography component="h2" className="modal_box--title">
                {totalQuantity} phone {totalQuantity > 1 ? "numbers" : "number"} selected
            </Typography>
            <Box className="modal_box--list-block">
            <Typography component="p" className="modal_box--subtitle">
                {userNumbersQuantity} user phone {userNumbersQuantity == 1 ? "number" : "numbers"}
            </Typography>
            <Typography component="p" className="modal_box--subtitle">
                {resourceNumbersQuantity} voice resource phone {resourceNumbersQuantity == 1 ? "number" : "numbers"}
            </Typography>
            </Box>
            {/* {poc && <Box className="modal_box--warning-block">
            <Typography component="p" className="modal_box--warning-title">
                E911 Disclosure
            </Typography>
            <Typography component="p" className="modal_box--warning-subtitle">
            Do not use your soft phone to dial 911 except from your registered physical location.
            Use at a location other than the registered physical location may route 911 calls
            to an incorrect 911 dispatch center, potentially delaying or preventing emergency services.
            It is recommended that you maintain alternative access to 911 services.
            </Typography>
            </Box>} */}
            <Typography component="span" className="modal_box--text">
                {
                // poc ? <span>
                //         Clicking confirm acknowledges that you understand the E911 disclosure and
                //         will begin the processing of your selected phone numbers.
                //         This action cannot be undone.
                //     </span> :
                    <span>
                        Clicking confirm will begin the processing of your selected phone numbers. 
                        This action cannot be undone.
                    </span>
                 }
            </Typography>
        </Box>
    )
}

export default ConfirmNumberSelection;
