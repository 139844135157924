import React, {useState} from 'react';
import { Outlet, use, useLocation } from "react-router-dom";
import {
    Box,
    Typography,
    CssBaseline,
    List,
    ListItem,
    ListItemIcon,
    Link,
    SvgIcon,
} from '@mui/material';
import User from './auth/User';
import {DrawerHeader, StyledMainDrawer, StyledMenuListItemText,StyledMenuListItemButton, StyledDrawerIconButton} from '../styles/mui_styles';
import {CUSTOMER_ROUTES, POC_MENU_ITEMS, CUSTOMER_MENU_ITEMS} from "../constants/constants";

function Layout() {
    const [open, setOpen] = useState(true);
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(prev => !prev);
    };

    const isCustomer = () => {
       return CUSTOMER_ROUTES.includes(location.pathname);
    }

    const menuItems = isCustomer() ? CUSTOMER_MENU_ITEMS : POC_MENU_ITEMS;

    return (
        <Box className="main_container">
            { console.log(location)}
            <CssBaseline/>
            <StyledMainDrawer variant="permanent" open={open} PaperProps={{elevation: 0}}>
                <DrawerHeader className="drawer_header" style={{justifyContent: open ? "flex-end" : 'flex-start'}}>
                    {open ? <Box component="svg" className="oc_logo"/> : <Box component="svg" className="oc_logo_small"/>}
                </DrawerHeader>

                <List className="list">
                    {menuItems.map((item, index) => (
                        <StyledMenuListItemButton
                            component={!isCustomer() && Link}
                            target="_blank"
                            to={item.link}
                            key={item.name}
                            selected={item.name == "Number Management" ? true : false}
                        >
                            {item.name == "Number Management" && <ListItemIcon className="item_icon"/>}
                            {open && <StyledMenuListItemText primary={item.name}/>}
                        </StyledMenuListItemButton>
                    ))}
                </List>
                <Box className='sidebar_bottom_text'>
                    @{new Date().getFullYear()} BluIP Inc. {" "}
                    <Link className='sidebar_bottom_span' target="_blank" href="https://www.bluip.com/company/legal/privacy-policy">
                        Privacy
                    </Link> {" "}
                        & {" "}
                    <Link className='sidebar_bottom_span' target="_blank" href="https://www.bluip.com/company/legal/terms-of-use">
                        Terms of Service
                    </Link>
                </Box>
            </StyledMainDrawer>
            <Box className="main_section">
                <Box className="header_block">
                    <StyledDrawerIconButton
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(open ? {transform: "translate(0%)"} : {transform: "rotate(180deg)"}),
                        }}
                    >
                        {open ? <Box component="svg" className="chevron_left"/> :
                            <Box component="svg" className="chevron_right"/>}
                    </StyledDrawerIconButton>
                    <Box className="main_header">
                       { isCustomer() ?
                        <User /> :
                        <SvgIcon className="teams_icon"/>
                       }
                    </Box>
                </Box>
                <Outlet />
            </Box>
        </Box>
    )
}

export default Layout
