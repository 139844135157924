import React, {useState} from 'react';
const { REACT_APP_LOGIN_REDIRECT_URI} = process.env;
import { IconButton, List, Typography} from '@mui/material';
import {StyledLogoutPopover, StyledLogoutMenuItem} from "../../styles/mui_styles";
import {useMsal} from "@azure/msal-react";

function User() {
    const {instance} = useMsal();
    const username = instance.getActiveAccount()?.username;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleLogOut = async () => {
        localStorage.removeItem("globalAdminInfo");
        localStorage.removeItem("logisenseInfo");
        await instance.logoutRedirect({
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: `${REACT_APP_LOGIN_REDIRECT_URI}/login`,
        });
        await instance.handleRedirectPromise();
    }

    return (
        <div className="user_block">
            {console.log(instance.getActiveAccount())}
            <Typography sx={{display: "inline-flex", marginRight:"14px !important", fontSize:"14px"}}>{username}</Typography>
            <IconButton className="user_circle" onClick={handleClick}/>
            <StyledLogoutPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{padding:"0"}}>
                    <StyledLogoutMenuItem onClick={handleLogOut}>
                       Sign out
                    </StyledLogoutMenuItem>
                </List>
            </StyledLogoutPopover>
        </div>
    );
}

export default User
