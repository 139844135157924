import React, {useState, useMemo, useEffect} from 'react'
import {useGetCustomerNumbersQuery, useGetUserTasksQuery, useGetTaskStatusMutation} from '../../services/requests'
import {
    Tabs,
    Paper,
    Typography,
    Box,
    MenuItem,
    SvgIcon,
    // TableBody,
    IconButton,
    InputAdornment,
    Select,
    Pagination,
    Button,
    Checkbox,
    Tooltip,
} from "@mui/material";
import {TABLE_HEADERS} from "../../constants/constants"
import {sortData} from "../../helpers/utils"
import {
    StyledIconButton,
    StyledTab,
    StyledSearchTextField,
    StyledTableContainer,
    StyledTable,
    StyledTableHead,
    StyledTableBody,
    StyledTableRow,
    StyledTableCell,
    StyledLargePrimaryButton,
    StyledDeleteButton,
    StyledStepperDialog,
    StyledStepperDialogContent,
    StyledPaginationSelect,
} from "../../styles/mui_styles";
import AddIcon from '@mui/icons-material/Add';
import Filters from "./Filters";
import AddNumbers from './add_numbers/AddNumbers';
import ActionAlert from '../sub_components/ActionAlert';
import DeleteNumbers from './delete_numbers/DeleteNumbers';
import {PendingActions} from '@mui/icons-material';
import { useOutletContext } from "react-router-dom";
import dayjs from 'dayjs';
import useGetCustomerNumbers from "../custom_hooks/useGetCustomerNumbers";


function CustomerNumbers() {
    const {tenantId, username} = useOutletContext();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [sort, setSort] = useState({sortField: "", sortType: null})
    const [searchValue, setSearchValue] = useState("")
    const [filters, setFilters] = useState({
        telephoneNumber: "All",
        isoCountryCode: "All",
        acquiredOn: {radioGroupValue: "All"},
        numberType: "All"
    })
    const [openAddNumbersDialog, setOpenAddNumbersDialog] = useState(false);
    const [openDeleteNumbersDialog, setOpenDeleteNumbersDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const {customerNumbers, isLoading, pendingNumbersQty, completedTasks, setCompletedTasks, refetchTasks} = useGetCustomerNumbers({tenantId, username});

    const numbers = useMemo(() => {
        if (customerNumbers) {
            
            const searchedData = searchValue ? customerNumbers.filter(item => item.telephoneNumber.includes(searchValue)) : customerNumbers;

            const filteredData = searchedData.filter((item) => {
                for (const key in filters) {
                    // if (key === "acquiredOn") {
                    //    console.log("filters[key]", filters[key])
                    //    console.log(item[key])
                    //    console.log(new Date(item[key]).getTime())
                    //    console.log(new Date(item[key]).getTime() >= filters[key].from && new Date(item[key]).getTime() <= filters[key].to)
                    // }

                    if (
                        filters[key] === "All" ||
                        item[key] === filters[key] ||
                        (key === "telephoneNumber" && (filters[key].includes(item.telephoneNumber.slice(2, 5)) || !filters[key].length)) ||
                        (key === "acquiredOn" &&
                        (filters[key].radioGroupValue === "All" ||
                        (!filters[key].from ?
                        new Date(item[key]).getTime() <= dayjs(filters[key].to).add(1, 'day').valueOf() :
                        !filters[key].to ?
                        new Date(item[key]).getTime() >= filters[key].from :
                        (new Date(item[key]).getTime() >= filters[key].from && new Date(item[key]).getTime() <= dayjs(filters[key].to).add(1, 'day').valueOf()))))
                    ) {
                        continue;
                    } else {
                        return false;
                    }
                }
                return true;
            })

            let dataToSort = JSON.parse(JSON.stringify(filteredData));


            if (sort.sortField) {
                dataToSort.sort(sortData(sort.sortType, sort.sortField));
            }

            // let dataToDisplay = dataToSort.map((item, index) => {
            //     item.telephoneNumberFormated = `${index + 1}. ${item.telephoneNumber}`
            //     return item;
            // })

            let paginatedData = dataToSort.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

            return {paginatedData, length: dataToSort.length, filteredData: dataToSort};
        }
        return {paginatedData: [], length: 0, filteredData: []};
    }, [customerNumbers, sort, page, rowsPerPage, searchValue, filters])

    useEffect(() => {
        if (numbers.filteredData.length && selected.length) {
            setSelected(prev => {
                const prevCopy = [...prev]
                const data = prevCopy.filter(number => {
                    return numbers.filteredData.find(item => item.telephoneNumber === number);
                })
                return data;
            });
        }
    }, [numbers])
    

    const areaCodes = useMemo(() => {
        const areaCodesSet = new Set();
        if (customerNumbers) {
            customerNumbers.forEach(item => {
                const areaCode = item.telephoneNumber.slice(2, 5)
                if (!areaCodesSet.has(areaCode)) {
                    areaCodesSet.add(areaCode)
                }
            })
        }

        return [...areaCodesSet].map(item => ({checked: false, value: item, visible: true}));
    }, [customerNumbers])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    }

    const handleSort = (column) => {
        setSort(prev => {
            return ({
                sortField: column,
                sortType: prev.sortField === column ? (prev.sortType === 1 ? -1 : 1) : -1
            })
        });
    }

    const handleSearch = (value) => {
        setSearchValue(value);
        setPage(1);
    }

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // useEffect(() => {
    //     const completedTask = taskResults.find(task => task.status == "Completed" && task.refetched == false);
    //     if (completedTask) {
    //         setTaskResults(prev => {
    //             const prevCopy = JSON.parse(JSON.stringify(prev));
    //             const task = prevCopy.find(task => task.id == completedTask.id);
    //             task.refetched = true;
    //             return prevCopy;
    //         })
    //         refetch();
    //     }
    // }, [taskResults])

    const handleSelection = (value) => {
        const selectedNumbers = [...selected];
        const numberIndex = selectedNumbers.findIndex(number => number === value);
        if (numberIndex == -1) {
            selectedNumbers.push(value);
        }
        if (numberIndex > -1) {
            selectedNumbers.splice(numberIndex, 1);
        }
        setSelected(selectedNumbers);
    }

    const handleSelectAll = () => {
        const totalLength = numbers.filteredData.length;
        const selectedNumbers = (totalLength - pendingNumbersQty) === selected.length ? 
            [] : 
            numbers.filteredData.filter(number => !number.pendingAddition && !number.pendingDeletion).map(number => number.telephoneNumber);
        setSelected(selectedNumbers);
    }


    return (
        <div className="table_section">
            <Box className="alert_block">
                {
                    completedTasks.map(task => {
                        return !task.customStatus?.seen && 
                        <ActionAlert
                                key={task.instanceId}
                                task={task}
                                setCompletedTasks={setCompletedTasks}
                        />
                    })
                }
            </Box>
            <Box className="tab_block">
                <Tabs TabIndicatorProps={{sx: {backgroundColor: "#4A85FF", height: "3px"}}} value={tabValue}
                      onChange={handleTabChange} aria-label="basic tabs example">
                    <StyledTab label="1. Numbers"/>
                </Tabs>
            </Box>

            {tabValue === 0 && <Box>
                <Box className="table_block">
                    <Box className="button_block">
                        <StyledSearchTextField
                            className="search_block"
                            size='small'
                            type="text"
                            placeholder='Search'
                            onChange={(event) => handleSearch(event.target.value)}
                            value={searchValue || ""}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><SvgIcon className="search"/></InputAdornment>),
                                endAdornment: true && (
                                    <StyledIconButton onClick={(event) => handleSearch("")}>
                                        <SvgIcon className="close_icon"/>
                                    </StyledIconButton>
                                )
                            }}/>
                        <Box className='table_block--inner'>
                            <StyledDeleteButton variant='outlined' onClick={() => setOpenDeleteNumbersDialog(true)}
                                                disabled={!selected.length}>Delete</StyledDeleteButton>
                            <StyledLargePrimaryButton onClick={() => setOpenAddNumbersDialog(true)}
                                                      startIcon={<AddIcon/>}>Add
                                Phone Numbers</StyledLargePrimaryButton>
                        </Box>
                    </Box>
                    {openAddNumbersDialog &&
                        <StyledStepperDialog onClose={() => setOpenAddNumbersDialog(false)} open={true} sx={{zIndex: "9999999"}}>
                            <StyledStepperDialogContent>
                                <AddNumbers setOpenDialog={setOpenAddNumbersDialog} tenantId={tenantId} refetchTasks={refetchTasks} username={username}/>
                            </StyledStepperDialogContent>
                        </StyledStepperDialog>
                    }
                    {openDeleteNumbersDialog && <DeleteNumbers
                        setOpenDialog={setOpenDeleteNumbersDialog}
                        tenantId={tenantId}
                        numbers={selected}
                        setSelected={setSelected}
                        refetchTasks={refetchTasks}
                        username={username}
                    />}
                    <Box className="table_block--height">
                        <StyledTableContainer component={Paper}>
                            <StyledTable stickyHeader size="small" aria-label="number table">
                                <StyledTableHead>
                                    <StyledTableRow>
                                        <StyledTableCell sx={{width: "6%"}} key="checkbox">
                                            <StyledIconButton
                                                size="small"
                                                style={{padding: 8}}
                                            >
                                                <Checkbox
                                                    checkedIcon={<SvgIcon className='checked_icon'/>}
                                                    icon={<SvgIcon className='unchecked_icon'/>}
                                                    sx={{paddingTop: 0, paddingBottom: 0}}
                                                    size="small"
                                                    onClick={handleSelectAll}
                                                    checked={numbers?.filteredData.length && selected.length && (numbers?.filteredData.length === (selected.length + pendingNumbersQty))}
                                                    disableRipple
                                                />
                                            </StyledIconButton>
                                        </StyledTableCell>
                                        {
                                            TABLE_HEADERS.map(header => {
                                                return (<StyledTableCell sx={{width: "25%"}} key={header.field}>
                                                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                                        <IconButton size="small"
                                                                    onClick={() => handleSort(header.field)}
                                                                    disabled={!numbers?.length}>
                                                            <SvgIcon
                                                                className={`sort_default ${sort.sortField === header.field ? sort.sortType === 1 ? 'sort_up' : 'sort_down' : ""}`}/>
                                                        </IconButton>

                                                        {header.name}

                                                        {<Filters
                                                            disabled={!customerNumbers?.length || (searchValue && !numbers.length && Object.values(filters).every(item => item === "All" || item.radioGroupValue == "All"))}
                                                            field={header.field} areaCodes={areaCodes || []}
                                                            filters={filters} setFilters={setFilters}
                                                            setPage={setPage}/>}

                                                    </Box>
                                                </StyledTableCell>)
                                            })
                                        }
                                    </StyledTableRow>
                                </StyledTableHead>
                                <StyledTableBody>
                                    {isLoading || (!customerNumbers)?
                                        <Box className="progress_bar"><SvgIcon className="loader"/></Box>
                                        :
                                        <> {numbers && numbers.length ? numbers.paginatedData.map((row, index) => (

                                                <StyledTableRow
                                                    key={row.telephoneNumber}
                                                    className={(row.pendingDeletion || row.pendingAddition) && "disabled"}
                                                >
                                                    <StyledTableCell>
                                                        <StyledIconButton
                                                            size="small"
                                                            style={{padding: 8}}
                                                            disabled={row.pendingDeletion || row.pendingAddition}
                                                        >
                                                            <Checkbox
                                                                checkedIcon={<SvgIcon className='checked_icon'/>}
                                                                icon={<SvgIcon className='unchecked_icon'/>}
                                                                sx={{paddingTop: 0, paddingBottom: 0}}
                                                                size="small"
                                                                onClick={() => handleSelection(row.telephoneNumber)}
                                                                checked={Boolean(selected.find(number => number == row.telephoneNumber))}
                                                                disableRipple
                                                            />
                                                        </StyledIconButton>
                                                    </StyledTableCell>
                                                    <StyledTableCell className='warning-flex'>
                                                        <div>{row.telephoneNumber}</div>
                                                        {row.pendingDeletion &&
                                                        <Tooltip title="Deletion in progress" placement="top-start">
                                                        <SvgIcon  className='pending-deletion'/>
                                                        </Tooltip>
                                                        }
                                                        {row.pendingAddition &&
                                                        <Tooltip title="This phone is pending" placement="top-start">
                                                            <SvgIcon  className='pending-addition'/>
                                                        </Tooltip>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.isoCountryCode}</StyledTableCell>
                                                    <StyledTableCell>{dayjs(row.acquiredOn).format("MM-DD-YYYY")}</StyledTableCell>
                                                    <StyledTableCell>{row.numberType}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                            :
                                            <Box className="empty_block">
                                                <SvgIcon className="search_icon"/>
                                                <Typography className="empty_block--title">Oops, no results
                                                    found</Typography>
                                            </Box>
                                        }
                                        </>
                                    }
                                </StyledTableBody>
                            </StyledTable>
                        </StyledTableContainer>
                        <Box className="pagination_block">
                            <Typography
                                className="pagination_block--items">{(rowsPerPage * page) < numbers?.length || 0 ? (rowsPerPage * page) : numbers?.length || 0} of {numbers?.length || 0} items</Typography>
                            <Pagination
                                sx={{
                                    '& .MuiPaginationItem-root.Mui-selected': {
                                        backgroundColor: '#4A85FF', color: "#fff"
                                    },
                                }}
                                count={Math.ceil((numbers?.length || 0) / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                            />
                            <Box className="pagination_block--rows">
                                <Typography className="pagination_block--items">Rows:</Typography>
                                <StyledPaginationSelect
                                    IconComponent={(props) => {
                                        return (<SvgIcon {...props} className={`${props.className} arrow-down`}/>)
                                    }}
                                    sx={{
                                        "&": {
                                            fontSize: "14px",
                                            color: "#4A85FF",
                                        },
                                        "&:after": {
                                            borderBottom: "none",
                                        },
                                        "&:focused": {
                                            backgroundColor: "#fff !important"
                                        },
                                        "&:hover:not(.Mui-disabled):before ": {
                                            borderBottom: "none",
                                        },
                                        "&:before": {
                                            borderBottom: "none",
                                        },
                                    }}
                                    value={rowsPerPage}
                                    variant="standard"
                                    onChange={handleChangeRowsPerPage}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </StyledPaginationSelect>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>}
        </div>
    )
}

export default CustomerNumbers


var mockActions = [
    {
        id: "37616312565656",
        status: "Completed",
        refetched: false,
        didQuantity: 3
    },
    {
        id: "37616312565657",
        status: "Completed",
        refetched: false,
        didQuantity: 2
    },
    {
        id: "37616312565658",
        status: "Failed",
        refetched: false,
        didQuantity: 5
    }
]

var mockTargetForDeletion = ["+17473489129", "+17473021834"]
