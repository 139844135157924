import React, {useState, useEffect} from 'react'
import {IconButton, RadioGroup, Typography, Box, SvgIcon} from "@mui/material";
import {StyledPopover, StyledRadio, StyledFormControlLabel,} from "../../styles/mui_styles";
import DateRangePicker from './DateRangePicker';
import AreaCodePicker from './AreaCodePicker';
import dayjs from 'dayjs';


function Filters({disabled, field, areaCodes, filters, setFilters, setPage}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [showCalendar, setShowCalendar] = useState(false);

    const [appliedAreaCodes, setAppliedAreaCodes] = useState(areaCodes);
    const [appliedAreaCodeRadioGroupValue, setAppliedAreaCodeRadioGroupValue] = useState("All");

    const [searchAreaCode, setSearchAreaCode] = useState("")

    useEffect(() => {
        if (areaCodes.length) {
            setAppliedAreaCodes(areaCodes)
        }
    }, [areaCodes])

    const handleFilterPopover = (event, action) => {
        const value = action === "open" ? event.currentTarget : null;
        setAnchorEl(value);
        if (action === "close" && showCalendar) {
            setShowCalendar(false);
        }
        setSearchAreaCode("");
        setAppliedAreaCodes(prev => {
            const prevCopy = prev.map(item => {
                const newItem = {...item};
                newItem.visible = true;
                return newItem;
            });
            return prevCopy;
        })
    };

    const handleFiltersStateChange = (field, value) => {
        let newValue = JSON.parse(JSON.stringify(value));

        if (field === "acquiredOn") {
            if (["All", "1", "7", "30", "60", "this_year", "last_year"].includes(value)) {
                newValue = {radioGroupValue: value};
                if (value == "1" || value == "7" || value == "30" || value == "60") {
                    const currentDate = dayjs().format("L");
                    const numberOfDays = parseInt(value) - 1;
                    newValue.from = dayjs(currentDate).subtract(numberOfDays, "day").valueOf()
                }
                if (value == "this_year") {
                    const currentYear = dayjs().get('year');
                    newValue.from = dayjs(`01/01/${currentYear}`).valueOf();
                }
                if (value == "last_year") {
                    const lastYear = dayjs().get('year') - 1;
                    newValue.from = dayjs(`01/01/${lastYear}`).valueOf();
                    newValue.to = dayjs(`12/31/${lastYear}`).valueOf();
                }
            } else {
                newValue.radioGroupValue = "";
            }
        }
        setFilters(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            prevCopy[field] = newValue;
            return prevCopy;
        });
        handleFilterPopover("_", "close")
        setPage(1);
    }

    return (
        <>
            <IconButton disabled={Boolean(disabled)} size="small" className="filter_block"
                        onClick={(e) => handleFilterPopover(e, "open")}>
                <SvgIcon className={`filter ${field == "acquiredOn" ?
                    filters.acquiredOn.radioGroupValue !== "All" ? "active" : disabled ? "disabled" : "" :
                    filters[field] !== "All" ? "active" : disabled ? "disabled" : ""}`}
                />
            </IconButton>
            <StyledPopover
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => handleFilterPopover(e, "close")}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transitionDuration={{exit: 10, enter: 200}}
            >
                {
                    field === "telephoneNumber" ?
                        <AreaCodePicker
                            handleFiltersStateChange={handleFiltersStateChange}
                            appliedAreaCodes={appliedAreaCodes}
                            setAppliedAreaCodes={setAppliedAreaCodes}
                            searchAreaCode={searchAreaCode}
                            setSearchAreaCode={setSearchAreaCode}
                            appliedAreaCodeRadioGroupValue={appliedAreaCodeRadioGroupValue}
                            setAppliedAreaCodeRadioGroupValue={setAppliedAreaCodeRadioGroupValue}
                        />
                        :
                        field === "isoCountryCode" ?
                            <RadioGroup
                                sx={{padding: "16px"}}
                                defaultValue="All"
                                value={filters.isoCountryCode}
                                onChange={(event) => {
                                    handleFiltersStateChange("isoCountryCode", event.target.value);
                                }}
                            >
                                <StyledFormControlLabel value="All" control={<StyledRadio size="small"/>}
                                                        label={<span style={{fontSize: '14px'}}>All</span>}/>
                                <StyledFormControlLabel value="US" control={<StyledRadio size="small"/>}
                                                        label={<span style={{fontSize: '14px'}}>US</span>}/>
                                <StyledFormControlLabel value="Canada" control={<StyledRadio size="small"/>}
                                                        label={<span style={{fontSize: '14px'}}>CA</span>}/>
                            </RadioGroup> :
                            field === "acquiredOn" ?
                                <Box className="data_picker_block">
                                    <Box className="data_picker_block--shortcuts">
                                        <RadioGroup
                                            defaultValue="All"
                                            value={filters.acquiredOn.radioGroupValue || null}
                                            onChange={(event) => {
                                                handleFiltersStateChange("acquiredOn", event.target.value);
                                            }}
                                        >
                                            <StyledFormControlLabel value="All" control={<StyledRadio size="small"/>}
                                                                    label={<span
                                                                        style={{fontSize: '14px'}}>All</span>}/>
                                            <StyledFormControlLabel value="1" control={<StyledRadio size="small"/>}
                                                                    label={<span
                                                                        style={{fontSize: '14px'}}>Today</span>}/>
                                            <StyledFormControlLabel value="7" control={<StyledRadio size="small"/>}
                                                                    label={<span
                                                                        style={{fontSize: '14px'}}>Last 7 days</span>}/>
                                            <StyledFormControlLabel value="30" control={<StyledRadio size="small"/>}
                                                                    label={<span
                                                                        style={{fontSize: '14px'}}>Last 30 days</span>}/>
                                            <StyledFormControlLabel value="60" control={<StyledRadio size="small"/>}
                                                                    label={<span
                                                                        style={{fontSize: '14px'}}>Last 60 days</span>}/>
                                            <StyledFormControlLabel value="this_year"
                                                                    control={<StyledRadio size="small"/>} label={<span
                                                style={{fontSize: '14px'}}>This year</span>}/>
                                            <StyledFormControlLabel value="last_year"
                                                                    control={<StyledRadio size="small"/>} label={<span
                                                style={{fontSize: '14px'}}>Last year</span>}/>
                                        </RadioGroup>
                                        <Box className='data_picker_block--custom_date'>
                                            <Typography className={`date_label ${!filters.acquiredOn.radioGroupValue && (filters.acquiredOn.from || filters.acquiredOn.to) ? "appliedDate" : ""}`}>
                                                {!filters.acquiredOn.radioGroupValue && filters.acquiredOn.from && filters.acquiredOn.to ?
                                                    `${dayjs(filters.acquiredOn.from).format('MM/DD/YYYY')} - ${dayjs(filters.acquiredOn.to).format('MM/DD/YYYY')}` :
                                                    !filters.acquiredOn.radioGroupValue && filters.acquiredOn.from ?
                                                        `From ${dayjs(filters.acquiredOn.from).format('MM/DD/YYYY')}` :
                                                        !filters.acquiredOn.radioGroupValue && filters.acquiredOn.to ?
                                                            `To ${dayjs(filters.acquiredOn.to).format('MM/DD/YYYY')}` :
                                                            "Custom date range"
                                                }
                                            </Typography>
                                            <IconButton size="small" style={{padding: 3}}
                                                        onClick={() => setShowCalendar((prev) => !prev)}>
                                                <SvgIcon className='right_arrow'/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {showCalendar &&
                                    <DateRangePicker
                                        handleFiltersStateChange={handleFiltersStateChange}
                                        appliedFromValue={filters.acquiredOn.from}
                                        appliedToValue={filters.acquiredOn.to}
                                    />}
                                </Box>
                                :
                                field === "numberType" ?
                                    <RadioGroup
                                        sx={{padding: "16px 16px 8px"}}
                                        defaultValue="All"
                                        value={filters.numberType}
                                        onChange={(event) => {
                                            handleFiltersStateChange("numberType", event.target.value);
                                        }}
                                    >
                                        <StyledFormControlLabel value="All" control={<StyledRadio/>}
                                                                label={<span style={{fontSize: '14px'}}>All</span>}/>
                                        <StyledFormControlLabel value="User" control={<StyledRadio/>}
                                                                label={<span style={{fontSize: '14px'}}>User</span>}/>
                                        <StyledFormControlLabel value="Resource" control={<StyledRadio/>} label={<span
                                            style={{fontSize: '14px'}}>Resource</span>}/>
                                    </RadioGroup> : ""

                }
            </StyledPopover>
        </>
    )
}

export default Filters
