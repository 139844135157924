import React, {useEffect} from 'react';
import {Box, Typography, Link} from "@mui/material";
import {StyledPrimaryButton, StyledLayoutCircularProgress} from "../styles/mui_styles";
import {useOutletContext, useNavigate} from "react-router-dom";
import {useGetLeadByTenantIdMutation} from '../services/requests';

function StartTrial() {
    const context = useOutletContext();
    const navigate = useNavigate();

    const [leadDataRequest, leadDataResponse] = useGetLeadByTenantIdMutation();

    const handleStartTrial = () => {
        leadDataRequest({tenantId: context.tenantId, sync: true})
    }

    useEffect(() => {
        if (leadDataResponse.data) {
            const leadData = leadDataResponse.data;
            if (leadData.status === "lead_not_processed") {
                navigate('../active_lead', {state: leadData})
            } else if (leadData.status === "lead_processed") {
                navigate('../lead_already_processed')
            } else {
                navigate('../not_found')
            }
        } else if (leadDataResponse.error) {
            navigate('../not_found')
        }
    }, [leadDataResponse])

    return (
        <>
            {leadDataResponse.isLoading || leadDataResponse.data || leadDataResponse.isError ?
                <Box sx={{display: 'flex'}}>
                    <StyledLayoutCircularProgress/>
                </Box>
                :
                <Box className="start_trial">
                    <Box className="start_trial--block">
                        <Box>
                            <Typography className="start_trial--title">
                                Get started with Operator Connect
                            </Typography>
                            <Typography className="start_trial--subtitle">
                                Select up to 25 phone numbers. If you need more, please {" "}
                                <Link target="_blank" href="mailto:sales@bluip.com"
                                      className="start_trial--subtitle_link">
                                    contact our Sales team.
                                </Link>
                            </Typography>
                        </Box>

                        <Typography className="start_trial--description">
                            BluIP is a Tier1 global service provider and communications technology innovator.
                            We've simplified the process of getting your company operational on
                            Microsoft Teams Calling to just a few easy steps.
                        </Typography>
                        <Box>
                            <Typography className="start_trial--step_title">
                                SET UP YOUR FIRST 25 PHONE NUMBERS, FREE FOR 30 DAYS:
                            </Typography>
                            <Box className="start_trial--step_block">
                                <Typography className="start_trial--step">
                                    <span className="step_number">Step 1: </span>
                                    Search and select your phone numbers
                                </Typography>
                                <Typography className="start_trial--step">
                                    <span className="step_number">Step 2: </span>
                                    Choose which phone numbers will be assigned as voice resource
                                </Typography>
                                <Typography className="start_trial--step">
                                    <span className="step_number">Step 3: </span>
                                    Fill out your contact information
                                </Typography>
                                <Typography className="start_trial--step">
                                    <span className="step_number">Step 4: </span>
                                    Done! You will receive a confirmation and activation email
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="step_button_block">
                        <StyledPrimaryButton onClick={handleStartTrial}>Start Trial</StyledPrimaryButton>
                    </Box>
                </Box>
            }
        </>
    )
}

export default StartTrial
