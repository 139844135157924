import {
    styled,
    TextField,
    FormHelperText,
    Button,
    Link,
    Alert,
    Checkbox,
    MenuItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    Stepper,
    StepLabel,
    Select,
    InputLabel,
    FormControl,
    Toolbar,
    IconButton,
    Tab,
    Drawer as MuiDrawer,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Popover,
    Radio,
    FormControlLabel,
    Chip,
    MobileStepper,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { PickersLayout } from '@mui/x-date-pickers/PickersLayout';
import React from "react";

import CalendarActiveIcon from "../styles/icons/Calendar_active.svg";

export let StyledTextField;
export let StyledAreaTextField;
export let StyledPrefixTextField;
export let StyledSelect;
export let StyledStepLabel;
export let StyledInputLabel;
export let StyledFormControl;
export let StyledContactFormControl;
export let StyledContactTextField;
export let StyledContactSelect;
export let StyledPaginationSelect;
export let StyledContactInputLabel;


export let StyledFormHelperText;
export let StyledWarningFormHelperText;
export let StyledMaxFormHelperText;

export let StyledSearchButton;
export let StyledLink;
export let StyledAlert;
export let StyledModalLink;

export let StyledCheckbox;
export let StyledMenuItem;
export let StyledListItemButton;
export let StyledMenuListItemButton;
export let StyledHoverListItemButton;
export let StyledListItemText;
export let StyledMenuListItemText;

export let StyledFilterCheckbox;
export let StyledChip;
export let StyledListItemIcon;

export let StyledDialog;
export let StyledDialogContent;
export let StyledDialogActions;

export let StyledCircularProgress;
export let StyledWhiteCircularProgress;
export let StyledLayoutCircularProgress;
export let StyledStepper;
export let StyledToolbar;
export let StyledIconButton;
export let DrawerHeader;
export let StyledDrawer;
export let StyledMainDrawer;

export let StyledTab;
export let StyledSearchTextField;
export let StyledPopover;
export let StyledRadio;
export let StyledFormControlLabel;
export let StyledCalendarTextField;

export let StyledTableContainer;
export let StyledTable;
export let StyledTableHead;
export let StyledTableRow;
export let StyledTableCell;
export let StyledTableBody;
export let StyledButton;
export let StyledDrawerIconButton;
export let StyledLogoutPopover;
export let StyledLogoutMenuItem;
export let StyledMediumPrimaryButton;
export let StyledLargePrimaryButton;
export let StyledMediumSecondaryButton;

export let StyledSecondaryButton;
export let StyledPrimaryButton;
export let StyledDeleteButton;
export let StyledDeletePrimaryButton;
export let StyledCancelButton;

export let StyledStepperDialog;
export let StyledStepperDialogContent;
export let StyledMobileStepper;

export let StyledDeleteDialog;
export let StyledDeleteDialogContent;

export let StyledBlockTypography;

export let StyledDatePicker;
export let StyledCalendarIconButton;
export let StyledPickersLayout;


StyledCalendarIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`;

StyledPickersLayout = styled(PickersLayout)((theme) => ({
    '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: '#4A85FF',
        "&:hover": {
            backgroundColor: '#EDF3FF',
            color: "#000",
        },
    },
    '& .MuiPickersDay-root.Mui-focused': {
        backgroundColor: '#4A85FF !important',
    },
    '& .MuiPickersDay-root:not(.Mui-selected)': {
        "&:focus": {
            backgroundColor: '#EDF3FF',
            color: "#000",
        },
    },
    '& .MuiPickersDay-root': {
        "&:hover": {
            backgroundColor: '#EDF3FF !important',
            color: "#000",
        },
    },

    '& .MuiDateCalendar-root': {
        width: "auto",
        height: "auto",
    },
    '& .MuiYearCalendar-root': {
        display: "block",
    },
    '& .MuiPickersYear-yearButton': {
        color: '#000',
        fontWeight: "600",
        height: "24px",
        backgroundColor: "#fff !important",
        "&:hover": {
            backgroundColor: "#fff !important",
            color: '#4A85FF',
        },
    },
    '& .MuiPickersYear-yearButton.Mui-selected': {
        color: "#4A85FF",
        backgroundColor: "#fff !important",
    },
}));
StyledDatePicker = styled(DatePicker)((theme) => ({
    "& .MuiOutlinedInput-root": {
        paddingRight: "0",
        fontSize: '14px',
        backgroundColor: "#FEFDFE",
        width: "230px",
        height: "46px",
        borderRadius: "8px",
        "& > fieldset": {
            borderColor: "#E5E6EA",
        },

    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",

        "& > fieldset": {
            borderColor: "#4A85FF",
            borderWidth: "1px",
        },
        ".calendar_icon": {
            backgroundImage: `url(${CalendarActiveIcon})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "20px",
            height: "20px",
        },
    },

    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#4A85FF",
        },
        ".calendar_icon": {
            backgroundImage: `url(${CalendarActiveIcon})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "20px",
            height: "20px",
        },

    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DDDDDD",
    },
}));

StyledStepper = styled(Stepper)((theme) => ({
    '& .MuiStepLabel-root .Mui-active .MuiSvgIcon-root': {
        color: '#4A85FF',
    },
    '& .MuiStepLabel-root .Mui-completed .MuiSvgIcon-root': {
        color: '#4A85FF',
    },

}));

StyledToolbar = styled(Toolbar)((theme) => ({
    '&.MuiToolbar-root': {
        "@media (min-width: 600px)": {
            minHeight: "50px !important",
        },
    }
}));
StyledStepLabel = styled(StepLabel)((theme) => ({
    '& .MuiStepLabel-label': {
        color: "#808080",
        marginTop: "8px !important",
        fontSize: "12px",
        fontWeight: "400",
        "&.Mui-active": {
            color: '#808080',
            fontWeight: "400",
        },
        "&.Mui-completed": {
            color: '#808080',
            fontWeight: "400",
        }
    },
}));

StyledFormHelperText = styled(FormHelperText)((theme) => ({
    "&.MuiFormHelperText-root": {
        color: "#F44336",
        margin: "0",
        fontWeight: "400",
        letterSpacing: "-0.1px",
        fontSize: "11px",
        position: "absolute",
    },
}));
StyledWarningFormHelperText = styled(FormHelperText)((theme) => ({
    "&.MuiFormHelperText-root": {
        color: "#F44336",
        fontWeight: "500",
        letterSpacing: "-0.1px",
        fontSize: "10px",
        position: "absolute",
    },
    "&.MuiFormHelperText-root.Mui-error": {
        margin: "0",
    }

}));
StyledMaxFormHelperText = styled(FormHelperText)((theme) => ({
    "&.MuiFormHelperText-root": {
        color: "#F44336",
        marginTop: "0 !important",
        fontWeight: "400",
        letterSpacing: "-0.1px",
        fontSize: "11px",
        position: "absolute",
        left: "-10px",
    },
}));
StyledAreaTextField = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-input": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        "&:-webkit-autofill": {
            // WebkitBoxShadow: "0 0 0 1000px #f0f0f0 inset"
        },
        "&:focus": {
            backgroundColor: "#E9ECF8"
        },
        "&:hover": {
            backgroundColor: "#E9ECF8"
        },
    },

    "& .MuiInputBase-root.MuiFilledInput-root.Mui-error": {
        backgroundColor: "#FDF7F6",
    },
    "& .MuiFilledInput-root": {
        color: "#5E6366",
        backgroundColor: "#f0f1f5",
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        borderRight: "1px solid #C1C7DE",
        "&:after": {
            color: "#4A85FF",
            borderBottom: "none",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&:before": {
            color: "#4A85FF",
            borderBottom: "none",
        }
    },
    "& .MuiFilledInput-input.Mui-error": {
        "&:after": {
            borderBottom: "none",
        }
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },

    "& .MuiInputLabel-root": {
        color: "#ABAFB1",
        fontSize: "13px",
        "@media (max-width: 660px)": {
            fontSize: "12px"
        },
    },

    "& .MuiFormHelperText-root.Mui-error": {
        fontSize: "11px",
        lineHeight: "1.3",
        color: "#F44336",
        marginLeft: "5px",
        position: "absolute",
        top: "50px",
    },

    "& .MuiInputLabel-root.Mui-error": {
        color: "#F44336"
    },

}));
StyledPrefixTextField = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-input": {
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        "&:-webkit-autofill": {
            // WebkitBoxShadow: "0 0 0 1000px #f0f0f0 inset"
        },
        "&:focus": {
            backgroundColor: "#E9ECF8"
        },
        "&:hover": {
            backgroundColor: "#E9ECF8"
        },
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-error": {
        backgroundColor: "#FDF7F6",
    },
    "& .MuiFilledInput-root": {
        color: "#5E6366",
        backgroundColor: "#f0f1f5",
        borderTopLeftRadius: "0px",
        "&:after": {
            color: "#4A85FF",
            borderBottom: "none",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&:before": {
            color: "#4A85FF",
            borderBottom: "none",
        }
    },
    "& .MuiFilledInput-root.Mui-error": {
        "&:after": {
            borderBottom: "none",
        }
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },

    "& .MuiInputLabel-root": {
        color: "#ABAFB1",
        fontSize: "13px",
        "@media (max-width: 660px)": {
            fontSize: "12px"
        },
    },

    "& .MuiInputLabel-root.Mui-error": {
        color: "#F15A24"
    },
    "& .MuiFormHelperText-root.Mui-error": {
        fontSize: "11px",
        lineHeight: "1.3",
        color: "#F44336",
        marginLeft: "5px",
        position: "absolute",
        top: "50px",
    },
}));
StyledContactTextField = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-input": {
        borderRadius: "8px",
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #4A85FF inset",
            backgroundColor: "#E9ECF8",
        },
        "&:focus": {
            backgroundColor: "#E9ECF8"
        },
    },
    "& .MuiFilledInput-input.Mui-focused": {
        backgroundColor: "#FDF7F6",
    },
    "& .MuiFilledInput-input.Mui-error": {
        backgroundColor: "#FDF7F6",
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-error": {
        backgroundColor: "#FDF7F6",
    },
    "& .MuiFilledInput-root": {
        color: "#5E6366",
        backgroundColor: "#F6F7FB",
        borderRadius: "8px",
        "&:after": {
            color: "#4A85FF",
            borderBottom: "none",
        },
        "&:hover": {
            backgroundColor: "#F6F7FB",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&:before": {
            color: "#4A85FF",
            borderBottom: "none",
        },
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#E9ECF8 !important",
    },
    "& .MuiFilledInput-root.Mui-error": {
        backgroundColor: "#FCF3F2",
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },

    "& .MuiInputLabel-root": {
        color: "#ABAFB1",
        fontSize: "16px",
        "@media (max-width: 660px)": {
            fontSize: "13px"
        },
    },

    "& .MuiFormHelperText-root.Mui-error": {
        fontSize: "11px",
        lineHeight: "1.3",
        color: "#F44336",
        marginLeft: "5px",
        position: "absolute",
        top: "50px",
    },

    "& .MuiInputLabel-root.Mui-error": {
        color: "#F44336"
    },
}));
StyledContactSelect = styled(Select)(({ theme }) => ({
    "&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },
    "& .MuiSelect-select": {
        color: "#5E6366 !important",
        backgroundColor: "#F6F7FB",
        borderRadius: "8px !important",
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f0f0f0 inset"
        },
        "& .Mui-error": {
            backgroundColor: "#FDF7F6",
        },
        "&:focus": {
            backgroundColor: "#E9ECF8 !important",
        },

    },


    "& .MuiFilledInput-root": {
        color: "#5E6366",
        borderRadius: "8px",
        "&:after": {
            color: "#4A85FF",
            borderBottom: "none",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&:before": {
            color: "#4A85FF",
            borderBottom: "none",
        },
        "&:focus": {
            backgroundColor: "#E9ECF8"
        },
    },
    "&.MuiFilledInput-root.Mui-focused": {
        "&:after": {
            color: "#4A85FF",
            borderColor: "#4A85FF",
            borderWidth: "2px",
        }
    },
    "&.MuiSvgIcon-root-MuiSelect-icon": {
        color: "#808080",
        "&:after": {
            color: "#4A85FF",
        }
    }
}));
StyledContactInputLabel = styled(InputLabel)(({ theme }) => ({
    "&.MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },
    "& .MuiInputLabel-root": {
        color: "#E9ECF8",
        fontSize: "16px",
        "@media (max-width: 660px)": {
            fontSize: "12px"
        },
    },
    "&.MuiFormLabel-root": {
        color: "#808080",
        fontSize: "13px",
    },

}));

StyledContactFormControl = styled(FormControl)(({ theme }) => ({
    "&.MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",

    },
    "&.MuiFilledInput-input": {
        color: "#808080",
    },
    "&.MuiFilledInput-root-MuiSelect-root.Mui-focused": {
        color: "#808080",
        letterSpacing: "0.3em",
        fontSize: "13px",
        minHeight: "44px",
        "&:after": {
            color: "#4A85FF",
            borderBottom: "2px solid #4A85FF",
        }
    },

}));
StyledPaginationSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select:focus": {
        backgroundColor: "#fff !important"
    },
}));

StyledSearchButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        // width: "170px",
        padding: "10px 55px",
        fontSize: "12px",
        backgroundColor: "#4A85FF",
        // "@media (max-width: 900px)": {
        //     width: "140px",
        // },
    }
}));
StyledLink = styled(Link)((theme) => ({
    "&.MuiLink-root": {
        color: "#F44336",
        textDecorationColor: "#F44336",
        cursor: "pointer",
    }
}));
StyledAlert = styled(Alert)((theme) => ({
    "&.MuiAlert-message": {
        padding: "4px 0 !important",
    },
}));
StyledMenuItem = styled(MenuItem)((theme) => ({
    "&.MuiMenuItem-root": {
        color: "#808080",
        "&:hover": {
            color: "#4A85FF",
        },

    }
}));
StyledModalLink = styled(Link)((theme) => ({
    "&.MuiLink-root": {
        color: "#4A85FF",
        textDecoration: "none",
        cursor: "pointer",
    }
}));

StyledSecondaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        color: "#4A85FF",
        border: "2px solid #4A85FF",
        backgroundColor: "#fff",
        padding: "8px 40px",
        fontSize: "18px",
        margin: "0",
        textTransform: "capitalize",
    }
}));

StyledPrimaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#4A85FF",
        color: "#fff",
        padding: "10px 40px",
        fontSize: "18px",
        margin: "0",
        textTransform: "capitalize",
        "&:disabled": {
            fontSize: "12px",
            backgroundColor: "#999999",
        }
    }
}));

StyledDeletePrimaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#F44336",
        color: "#fff",
        padding: "8px 40px",
        fontSize: "16px",
        margin: "0",
        textTransform: "capitalize",
    }
}));
StyledDeleteButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#F44336",
        border: "2px solid #F44336",
        padding: "6px 40px",
        fontSize: "14px",
        textTransform: "capitalize",
        margin: "0",
        "&:disabled": {
            fontSize: "14px",
            border: "2px solid #979797",
            color: "#515151",
        }
    }
}));
StyledCancelButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#515151",
        border: "2px solid #979797",
        padding: "6px 40px",
        fontSize: "16px",
        textTransform: "capitalize",
        margin: "0",
    }
}));
StyledCheckbox = styled(Checkbox)((theme) => ({
    "&.MuiCheckbox-root": {
        color: "#999999",
        padding: "5px",
    },
    "&.MuiCheckbox-root.Mui-checked": {
        color: "#4A85FF",
    },
    "&.MuiCheckbox-indeterminate ": {
        color: "#4A85FF",
    }
}));
StyledListItemButton = styled(ListItemButton)((theme) => ({
    "&.MuiListItemButton-root": {
        paddingTop: "2px",
        paddingBottom: "2px",
    }
}));
StyledMenuListItemButton = styled(ListItemButton)((theme) => ({
    "&.MuiListItemButton-root": {
        "&.Mui-selected ": {
            borderRight: "3px solid #4A85FF",
            backgroundColor: "#082851",
        },
        "&:hover": {
            borderRight: "3px solid #4A85FF",
            backgroundColor: "#082851",
        }
    }
}));
StyledHoverListItemButton = styled(ListItemButton)((theme) => ({
    "&.MuiListItemButton-root": {
        paddingLeft: "5px",
        paddingRight: "0px",
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));
StyledListItemText = styled(ListItemText)((theme) => ({
    "&.MuiListItemText-root": {
        color: "#808080",
    }
}));
StyledMenuListItemText = styled(ListItemText)((theme) => ({
    "&.MuiListItemText-root": {
        color: "#F2F4F5",
        ".MuiTypography-root": {
            fontSize: "14px"
        }
    },
}));

StyledDialog = styled(Dialog)((theme) => ({
    "&.MuiDialog-root ": {
        padding: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    }
}));
StyledDialogContent = styled(DialogContent)((theme) => ({
    "&.MuiDialogContent-root ": {
        padding: "30px 30px 20px 30px",
    }
}));
StyledDialogActions = styled(DialogActions)((theme) => ({
    "&.MuiDialogActions-root ": {
        padding: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "&.MuiDialogActions-root>:not(:first-of-type)": {
        marginLeft: "16px"
    },
}));
StyledCircularProgress = styled(CircularProgress)((theme) => ({
    "&.MuiCircularProgress-root ": {
        color: "#4A85FF",
        backgroundColor: "transparent",
    }
}));
StyledWhiteCircularProgress = styled(CircularProgress)((theme) => ({
    "&.MuiCircularProgress-root ": {
        color: "#fff",
        height: "26px !important",
        width: "26px !important"
    }
}));

StyledLayoutCircularProgress = styled(CircularProgress)((theme) => ({
    "&.MuiCircularProgress-root ": {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        color: "#4A85FF",
        backgroundColor: "transparent",
    }
}));


StyledIconButton = styled(IconButton)`
  & {
    padding: 8px 0;
  }

  &:hover {
    background-color: transparent;
  }

  && .MuiTouchRipple-child {
    width: 0;
    height: 0;
  }
`;

DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    // padding: "0 0 0 32px",
    "@media (max-width: 600px)": {
        padding: "0 0 0 20px",
    }
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: '#80a1fd',
    height: '100%',
    border: 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(8)} + 100px)`,
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 2px)`,
    backgroundColor: '#80a1fd',
    height: '100%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(6)} + 20px)`,
        backgroundColor: '#80a1fd',
        height: '100%',
        border: 'none',
    },
});
StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const mainDrawerWidth = 214;

const openedDrawerMixin = (theme) => ({
    width: mainDrawerWidth,
    zIndex: 1,
    backgroundColor: '#052E5C',
    height: '100%',
    border: 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(8)} + 100px)`,
    },
});

const closedDrawerMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 2px)`,
    backgroundColor: '#052E5C',
    height: '100%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(6)} + 20px)`,
        backgroundColor: '#052E5C',
        height: '100%',
        border: 'none',
    },
});


StyledMainDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedDrawerMixin(theme),
            '& .MuiDrawer-paper': openedDrawerMixin(theme),
        }),
        ...(!open && {
            ...closedDrawerMixin(theme),
            '& .MuiDrawer-paper': closedDrawerMixin(theme),
        }),
    }),
);

StyledTab = styled(Tab)((theme) => ({
    "&.MuiTab-root": {
        padding: "0 32px 0 2px",
        "&.Mui-selected": {
            color: "#252423",
            padding: "0 60px 0 2px",
            fontWeight: "600",
            textTransform: "capitalize"
        },
    },
}));

StyledSearchTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        paddingRight: "0",
        fontSize: '12px',
        borderRadius: '4px',
        backgroundColor: "#FEFDFE",
        "& > fieldset": {
            borderColor: "#E5E6EA",
        }
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",

        "& > fieldset": {
            borderColor: "#4A85FF",
            borderWidth: "1px",
        },
    },

    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#4A85FF",
        }
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#4A85FF",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },
}));


StyledTableContainer = styled(TableContainer)((theme) => ({
    width: "100%",
    maxHeight: "100%",
    marginRight: "16px",
    maxWidth: 400,
    border: "0.5px solid #E5E6EA",
    overflow: "scroll",
    minWidth: "-webkit-fill-available",
    "&.MuiTableContainer-root": {
        "&::-webkit-scrollbar": {
            height: "6px",
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(187, 194, 201, 0.70)",
                borderRadius: "5px",
            }
        }
    },
    "&.MuiPaper-root": {
        minHeight: 400,
        height: "inherit",
        boxShadow: "0 0 0 #fff",
        borderRadius: "0px",
        overflow: "auto",
    }
}));

StyledTable = styled(Table)((theme) => ({
    "&.MuiTable-root": {
        minWidth: 650,
        height: "max-content",
        whiteSpace: "nowrap",
        width: "-webkit-fill-available",
        position: "relative",
    }
}));

StyledTableHead = styled(TableHead)((theme) => ({
    "&.MuiTableHead-root": {
        display: "table-header-group",
        top: "0",
        color: "#252423",
        backgroundColor: "#f7f7f8",
        "@media (max-width: 1200px)": {
            fontSize: "12px",
        },
    },
}));

StyledTableBody = styled(TableBody)((theme) => ({
    "&.MuiTableBody-root": {
        display: "table-row-group",

    },
}));

StyledTableRow = styled(TableRow)((theme) => ({
    "&.MuiTableRow-root": {
        color: "rgba(37, 36, 35, 0.74)",
        height: "40px",
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: "#F6F9FF",
            cursor: "pointer",
        },
        "&.disabled": {
            backgroundColor: "#F2F4F5",
            cursor: "default",
            "&.MuiTableCell-root": {
                color: "#BBBBBB",
            },
        }
    },
    "&.MuiTableRow-root:last-child td": {
        // border: "0.5px solid #E5E6EA",
    },
    "&.MuiTableRow-root:last-child th": {},
}));
StyledTableCell = styled(TableCell)((theme) => ({
    "&.MuiTableCell-root": {
        border: "0.5px solid #E5E6EA",
        color: "rgba(37, 36, 35, 0.74)",
        padding: "1px 16px",
        fontSize: "12px",
        "&:last-child": {
            width: "10px",
            // border: "0.5px solid #E5E6EA",
        },
        "&.data_loading": {
            color: "#C8C8C8"
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "1px 8px",
        },

        "&.MuiTableCell-head": {
            "&:th": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            },
        },
        "&.MuiTableRow-root": {
            "&:last-child:th": {
                borderRight: "0px",
            },
        },
        "&.MuiTableCell-root": {
            "&:last-child": {
                borderRight: "0px",
            },
        },

    },

    "&.MuiTableCell-head": {
        color: "#3f4244",
        padding: "4px 16px !important",
        fontSize: "12px",
        fontWeight: "600",
        cursor: "pointer",
        backgroundColor: "#F3F4F6",

        "@media (max-width: 600px)": {
            fontSize: "12px",
        },
    },

}));


StyledPopover = styled(Popover)((theme) => ({
    '& .MuiPopover-paper': {
        border: "1px solid #E1E1E7",
        borderRadius: "8px",
        boxShadow: "0px 3px 20px -8px rgba(0,0,0,12%)",
    },
}));
StyledLogoutPopover = styled(Popover)((theme) => ({
    '& .MuiPopover-paper': {
        border: "1px solid #E1E1E7",
        borderRadius: "5px",
        boxShadow: "0px 3px 20px -8px rgba(0,0,0,12%)",
    },
}));

StyledRadio = styled(Radio)((theme) => ({
    '&.Mui-checked': { color: '#99D04F' },
}));


StyledFormControlLabel = styled(FormControlLabel)((theme) => ({
    '& .MuiFormGroup-root': {
        padding: "16px",
    },
    '&.MuiFormControlLabel-label': {
        fontSize: "14px",
    },
}));
StyledListItemIcon = styled(ListItemIcon)((theme) => ({
    "&.MuiListItemIcon-root": {
        minWidth: "0",
    }
}));
StyledChip = styled(Chip)((theme) => ({
    "&.MuiChip-root": {
        borderRadius: "4px",
        backgroundColor: "#F3F4F6",
        color: "#333333",
        margin: "4px",
    }
}));

StyledButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        '&.Mui-disabled': {
            color: "#808080 !important"
        }
    }
}));
StyledDrawerIconButton = styled(IconButton)((theme) => ({
    "&.MuiIconButton-root": {
        position: "relative",
        top: "47px",
        right: "17px",
        zIndex: "9999",
    }
}));

StyledCalendarTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        paddingRight: "0",
        fontSize: '12px',
        borderRadius: '4px',
        backgroundColor: "#FEFDFE",
        marginRight: "12px",
        "& > fieldset": {
            borderColor: "#E5E6EA",
        }
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",

        "& > fieldset": {
            borderColor: "#4A85FF",
            borderWidth: "1px",
        },
    },

    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#4A85FF",
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: "8.5px 0 8.5px 14px"
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#4A85FF",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4A85FF",
    },

}));
StyledLogoutMenuItem = styled(MenuItem)((theme) => ({
    "&.MuiMenuItem-root": {
        paddingLeft: "26px",
        paddingRight: "26px",
        fontSize: "14px",
        color: "#79747E",
    }
}));
StyledMediumPrimaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        width: "123px",
        backgroundColor: "#4A85FF",
        color: "#fff",
        padding: "8px 20px",
        textTransform: "capitalize",
        fontSize: "16px",
        margin: "0",
        "&:disabled": {
            backgroundColor: "#BED3FF",
        },
    }
}));
StyledLargePrimaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#4A85FF",
        color: "#fff",
        padding: "8px 20px",
        textTransform: "capitalize",
        fontSize: "14px",
        margin: "0",
    }
}));
StyledMediumSecondaryButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        width: "123px",
        backgroundColor: "#fff",
        color: "#4A85FF",
        border: "1px solid #4A85FF",
        padding: "8px 20px",
        textTransform: "capitalize",
        fontSize: "14px",
        margin: "0",
    }
}));
StyledStepperDialog = styled(Dialog)((theme) => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
            borderRadius: "20px",
            padding: "20px 40px"
        },
    },
}));
StyledStepperDialogContent = styled(DialogContent)((theme) => ({
    "&.MuiDialogContent-root ": {
        padding: "0",
        height: "600px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }
}));
StyledMobileStepper = styled(MobileStepper,)((theme) => ({
    "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: "#DDE8FF",
        height: "12px",
        borderRadius: "20px",
        width: "100%",
    },
    "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#4A85FF",
        height: "12px",
        borderRadius: "20px",
    },
}));

// Delete Dialog
StyledDeleteDialog = styled(Dialog)((theme) => ({
    "& .MuiModal-root-MuiDialog-root": {
        zIndex: "999999",
    },
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
            height: "680px",
            borderRadius: "20px",
            padding: "40px",
        },
    },
}));

StyledDeleteDialogContent = styled(DialogContent)((theme) => ({
    "&.MuiDialogContent-root ": {
        padding: "0",
        overflow: "auto",
    }
}));
