import React, {useState, useEffect} from 'react';
import {Box, Alert, IconButton, Slide} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useRaiseTaskEventMutation} from '../../services/requests'

function ActionAlert({task, setCompletedTasks}) {
    const result = task.customStatus.result;
    const didQuantity = task.input.phoneNumbers.length;
    const id = task.instanceId;
    const [open, setOpen] = useState(true);
    const [raiseTaskEventRequest] = useRaiseTaskEventMutation();


    useEffect(() => {
        raiseTaskEventRequest({instanceId: id, event: "Seen"})
    }, []);

    const handleClose = () => {
        setOpen(false);
        setCompletedTasks(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            const taskIndex = prevCopy.findIndex(task => task.instanceId == id);
            prevCopy[taskIndex].customStatus.seen = true;
            return prevCopy;
        });
    }

    const getMessage = () => {
        if (task.name === "AddNumbersOrchestrator") {
            const message = result == "success" ?
                `${didQuantity} phone number${didQuantity > 1 ? "s" : ""} added successfully!` :
                "An error occured while adding the numbers!";
            return message;
        }
        if (task.name === "DeleteNumbersOrchestrator") {
            const message = result == "success" ?
                `${didQuantity} phone number${didQuantity > 1 ? "s have" : " has"} been deleted successfully!` :
                "An error occured while deleting the numbers!";
            return message;
        }
    }

    return (
        <Box className={`${result == "success" ? "success" : "error"}`}>
            <Slide in={open} timeout={800} direction='left'>
                <Alert
                    className={`${result == "success" ? "success_alert" : "error_alert"}`}
                    severity={result == "success" ? "success" : "error"}
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }
                    sx={{mb: 2}}
                    >
                    {getMessage()}
                </Alert>
            </Slide>
        </Box>
    );
}

export default ActionAlert;
