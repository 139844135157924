import { memo } from "react";
import {ListItem, ListItemText, Checkbox, SvgIcon} from "@mui/material";
import {
    StyledListItemIcon,
    StyledHoverListItemButton,
} from "../../../styles/mui_styles";

const MemoizedListItem = memo(function MemoizedListItem ({number, checked, resource, selectResource, handleDIDSelection}) {

    return (
        <ListItem key={number} component="div" disablePadding>
            <StyledHoverListItemButton role={undefined} onClick={() => handleDIDSelection({number, checked, resource})} dense>
                <StyledListItemIcon>
                    <Checkbox
                        checkedIcon={<SvgIcon className='checked_icon'/>}
                        icon={<SvgIcon className='unchecked_icon'/>}
                        sx={{paddingTop: 0, paddingBottom: 0}}
                        size="small"
                        edge="start"
                        checked={selectResource ? resource: checked}
                        disableRipple
                    />
                </StyledListItemIcon>
                <ListItemText className="number_block--num_text" primary={number} />
            </StyledHoverListItemButton>
        </ListItem>
    )
});

export default MemoizedListItem;