import React, {useState, useEffect} from 'react';
import {Box, Button, Typography, Link, CircularProgress, SvgIcon} from '@mui/material';
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser"
import {loginRequest} from "../../config/auth_config";
import {Navigate} from "react-router-dom";
import LoginInProgress from './LoginInProgress';
import {getUserRoles} from '../../services/getUserRoles';
import {getAccessToken} from "../../services/getAccessToken";
import {useGetCustomerMutation, useGetLeadByTenantIdMutation, useGetOCCustomerMutation} from '../../services/requests';

function Login() {

    const {instance, inProgress, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated()

    const [globalAdminInfo, setGlobalAdminInfo] = useState(localStorage.getItem("globalAdminInfo"));
    const [logisenseCustomerInfo, setLogisenseCustomerInfo] = useState(localStorage.getItem("logisenseInfo"));
    const [customerRequest, customerResponse] = useGetCustomerMutation();
    const [leadDataRequest, leadDataResponse] = useGetLeadByTenantIdMutation();
    const [ocCustomerRequest, ocCustomerResponse] = useGetOCCustomerMutation();

    useEffect(() => {
        if (isAuthenticated) {
            const checkIfGlobalAdminAndLogisenseCustomer = async () => {
                try {
                    const globalAdminInfo = localStorage.getItem("globalAdminInfo");
                    if (!globalAdminInfo) {
                        const token = await getAccessToken("silent", "graph");
                        const roles = await getUserRoles(token);
                        const globalAdmin = roles.value.find(item => item["@odata.type"] === "#microsoft.graph.directoryRole" && item.roleTemplateId === "62e90394-69f5-4237-9190-012177145e10");
                        const isGlobalAdmin = globalAdmin ? "yes" : "no";
                        localStorage.setItem("globalAdminInfo", isGlobalAdmin);
                        setGlobalAdminInfo(isGlobalAdmin);
                    }
                } catch (err) {
                    localStorage.setItem("globalAdminInfo", "no");
                    setGlobalAdminInfo("no");
                    console.log(err)
                }
                try {
                    const logisenseInfo = localStorage.getItem("logisenseInfo");
                    if (!logisenseInfo) {
                        const lead = await leadDataRequest({tenantId: accounts[0].tenantId, sync: false}).unwrap();
                        const ocCustomerData = await ocCustomerRequest({customerId: lead.lead_data.umpCustomerGuid}).unwrap();
                        const logisenseCustomer = await customerRequest({customerName: ocCustomerData.FullName}).unwrap();
                        const isLogisenseCustomer = logisenseCustomer && logisenseCustomer.UserStatusType === "Enabled" ? "yes" : "no"
                        localStorage.setItem("logisenseInfo", isLogisenseCustomer);
                        setLogisenseCustomerInfo(isLogisenseCustomer);
                    }
                } catch (err) {
                    localStorage.setItem("logisenseInfo", "no");
                    setLogisenseCustomerInfo("no");
                    console.log(err)
                }
            }
            checkIfGlobalAdminAndLogisenseCustomer();
        }
    }, [isAuthenticated])


    async function handleLogin() {
        await instance.loginRedirect(loginRequest);
    }

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
        return (
            <Box className="login_container">
                <Box className="login_block">
                    <Box className="login_block--cover"/>
                    <Box className="login_block--shadow">
                        <Typography className="login_block--heading">Welcome to BluIP's Operator
                            Connect <br/> Management
                            Portal</Typography>
                        <Typography className="login_block--paragraph" component="span">
                            Manage your Operator Connect phone numbers quickly and easily!
                            <Typography className="login_block--paragraph">
                                View all your phone numbers, order new phone numbers <br/>
                                and even delete existing phone numbers all in one place!
                            </Typography>
                        </Typography>

                        <Button
                            className="login_block--button"
                            variant='contained'
                            onClick={() => handleLogin()}
                        >
                            Log in with Azure
                        </Button>
                        <Box className="login_block--footer">
                            <Typography component="span" className="login_block--footer_text" id="footer_text">By signing in you agree to Operator Connect's</Typography>
                            <Link size="small" className="login_block--footer_text_pt text_t" target="_blank"
                                  id="footer_link_2" href="https://www.bluip.com/company/legal/terms-of-use">Terms of Use</Link>
                            <Typography component="span" className="login_block--footer_text"
                                        id="footer_text">&</Typography>
                            <Link size="small" className="login_block--footer_text_pt" target="_blank"
                                  id="footer_link_1"
                                  href="https://www.bluip.com/company/legal/privacy-policy">Privacy Policy</Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    } else if (
        isAuthenticated && (
        inProgress === InteractionStatus.Startup ||
        inProgress === InteractionStatus.HandleRedirect ||
        !logisenseCustomerInfo ||
        !globalAdminInfo)
    ) {
        return <LoginInProgress/>
    } else if (logisenseCustomerInfo === "no" || globalAdminInfo === "no") {
        return <Navigate to="/access_denied"/>
    } else if (isAuthenticated && logisenseCustomerInfo === "yes" || globalAdminInfo === "yes") {
        return <Navigate to="/numbers"/>
    } else {
        return null;
    }
}

export default Login;
