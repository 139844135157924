import React, {useState} from 'react'
import {Typography, Box, Grid, Link, SvgIcon} from "@mui/material";
import {
    useDeletePhoneNumbersMutation,
    useGetTaskStatusMutation
} from "../../../services/requests";
import {
    StyledDeleteDialog,
    StyledDeleteDialogContent,
    StyledCancelButton,
    StyledDeletePrimaryButton,
    StyledMediumPrimaryButton
} from '../../../styles/mui_styles';
import zIndex from '@mui/material/styles/zIndex';

function DeleteNumbers({setOpenDialog, tenantId, numbers, setTargetedForDeletion, setTaskResults, setSelected, setDeleted, refetchTasks, username}) {
    const [request, response] = useDeletePhoneNumbersMutation();
    const [assignedNumbers, setAssignedNumbers] = useState([]);
    const [someNumbersAreAssigned, setSomeNumbersAreAssigned] = useState(false);
    const [getTaskStatusRequest, getTaskStatusResponse] = useGetTaskStatusMutation();

    const handleDelete = async () => {
        try {
            const resp = await request({phoneNumbers: numbers.map(number => number.slice(2)), tenantId, username}).unwrap();
            refetchTasks();
            setSelected(prev => {
                const prevCopy = [...prev]
                const data = prevCopy.filter(number => {
                    return !numbers.find(item => item == number);
                })
                return data;
            });
        } catch (error) {
            if (error.data.detail.includes("numbers are still assigned so they cannot be released")) {
                const assignedNumbers = error.data.detail.split("[")[1].split("]")[0].split(", ");
                setAssignedNumbers(assignedNumbers);
                setSomeNumbersAreAssigned(true);
            }
        }
    }

    const deleteUnassignedNumbers = async () => {
        try {
            const phoneNumbers = numbers.filter(number => !assignedNumbers.includes(number));
            const resp = await request({phoneNumbers: phoneNumbers.map(number => number.slice(2)), tenantId, username}).unwrap();
            refetchTasks();
            setSelected(prev => {
                const prevCopy = [...prev]
                const data = prevCopy.filter(number => {
                    return !phoneNumbers.find(item => item == number);
                })
                return data;
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
    }

    const checkTaskStatus = async (timeoutId, url, taskStatus, id, phoneNumbers) => {
        clearTimeout(timeoutId);
        if (taskStatus === "Pending" || taskStatus === "Running") {
            timeoutId = setTimeout(async () => {
                const resp = await getTaskStatusRequest(url).unwrap();
                checkTaskStatus(timeoutId, url, resp.runtimeStatus, id, phoneNumbers)
            }, 5000)
        } else {
            setTaskResults(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                prevCopy.push({
                    id: id,
                    status: taskStatus,
                    refetched: false,
                    didQuantity: phoneNumbers.length,
                    action: "DELETE_NUMBERS"
                });
                return prevCopy;
            })
            if (taskStatus === "Completed") {
                console.log(taskStatus)
                setDeleted(prev => {
                    let prevCopy = [...prev];
                    prevCopy = prevCopy.concat(phoneNumbers);
                    return prevCopy;
                });
                setTargetedForDeletion(prev => {
                    let prevCopy = [...prev];
                    return prevCopy.filter(item => !phoneNumbers.includes(item));
                });
            }
        }
    }

    const unassignedNumbers = () => {
        return numbers.filter(number => !assignedNumbers.includes(number))
    }

    return (
        <StyledDeleteDialog open={true} sx={{zIndex: "9999999"}}>
            <StyledDeleteDialogContent>
                {
                    // false &&
                    response.isUninitialized &&
                    <Box className='delete_dialog_block'>
                        <SvgIcon className='delete_dialog_block--icon'/>
                        <Box className='delete_dialog_block--text-block'>
                            <Box className='delete_dialog_block--text-box'>
                                <Typography className='delete_dialog_block--title'>Delete Number</Typography>
                                <Typography className='delete_dialog_block--paragraph'>
                                    Are you sure you want to delete the
                                    {numbers.length > 1 ? " selected phone" : " following phone"} number{numbers.length > 1 ? "s?" : "?"}
                                </Typography>
                                {numbers.length == 1 && <Typography className='delete_dialog_block--paragraph-number'>
                                    {numbers[0]}
                                </Typography>}
                            </Box>
                            <Box className='delete_dialog_block--note'>
                                <Typography className='delete_dialog_block--warning_title'>
                                    Note:
                                </Typography>
                                <Typography className='delete_dialog_block--warning_span'>Phone numbers currently
                                    assigned to a user must first be
                                    unassigned in the Microsoft Teams Admin portal.
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='delete_dialog_block--button_box'>
                            <StyledCancelButton variant="outlined" onClick={handleClose}>Cancel</StyledCancelButton>
                            <StyledDeletePrimaryButton variant="contained"
                                                       onClick={handleDelete}>Delete</StyledDeletePrimaryButton>
                        </Box>
                    </Box>
                }

                {
                    // true &&
                    response.isError && someNumbersAreAssigned && assignedNumbers.length === numbers.length &&
                    <Box className='delete_dialog_block'>
                        <SvgIcon className='delete_dialog_block--icon-note'/>
                        <Box className='delete_dialog_block--text-block-note'>
                            <Typography className='delete_dialog_block--title'>Please Note:</Typography>
                            <Typography className='delete_dialog_block--note-paragraph'>
                                The Phone number{numbers.length > 1 ? "s" : ""} you
                                selected {numbers.length > 1 ? "are" : "is"} currently assigned to a user and cannot be
                                deleted.
                                Please use the Microsoft Teams Admin portal to first unassign the phone
                                number{numbers.length > 1 ? "s" : ""}.
                            </Typography>
                            <Typography className='delete_dialog_block--paragraph-width'>
                                Once unassigned, you can return to this portal to perform the
                                deletion{numbers.length > 1 ? "s" : ""}.
                            </Typography>
                        </Box>
                        <Box className='delete_dialog_block--button_box'>
                            <StyledMediumPrimaryButton variant="contained"
                                                       onClick={handleClose}>Ok</StyledMediumPrimaryButton>
                        </Box>
                    </Box>
                }

                {
                    // true &&
                    response.isError && someNumbersAreAssigned && assignedNumbers.length < numbers.length &&
                    <Box className='delete_dialog_block'>
                        <SvgIcon className='delete_dialog_block--icon'/>
                        <Box className='delete_dialog_block--text-block-list'>
                            <Box className='delete_dialog_block--text-box'>
                                <Typography className='delete_dialog_block--title'>Delete Number</Typography>
                                <Box className='delete_dialog_block--grid-block'>
                                    <Typography className='delete_dialog_block--paragraph'>
                                        Do you want to delete all <span className='bold'> UNASSIGNED </span>phone
                                        numbers?
                                    </Typography>

                                    <Grid className={`delete_dialog_block--grid-container ${unassignedNumbers()?.length < 6 ? "center" : "leftAlign"}`} container>
                                    {
                                        unassignedNumbers()?.map(number => {
                                            return <Grid className="delete_dialog_block--grid" item
                                                         textAlign="center"
                                                         key={number}>
                                                <SvgIcon className='delete_dialog_block--dot'/>
                                                <Typography className='delete_dialog_block--grid-paragraph'
                                                            key={number}>
                                                    {number}
                                                </Typography>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                                <Typography className='delete_dialog_block--warning_red'>
                                        Deleting a phone number cannot be undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className='delete_dialog_block--warning'>
                                <Typography className='delete_dialog_block--warning_title'>
                                    Warning:
                                </Typography>
                                <Typography className='delete_dialog_block--warning_span'>
                                    The following phone number{assignedNumbers.length > 1 ? "s" : ""} cannot be
                                    deleted
                                    as {assignedNumbers.length > 1 ? "they are" : "it is"} currently assigned to a
                                    user</Typography>
                                <Grid className="delete_dialog_block--grid-container-align-left" container>
                                    {
                                        assignedNumbers.map(number => {
                                            return <Grid className="delete_dialog_block--grid" item
                                                         textAlign="center"
                                                         key={number}>
                                                <SvgIcon className='delete_dialog_block--dot-gray'/>
                                                <Typography className='delete_dialog_block--grid-paragraph'
                                                            key={number}>
                                                    {number}
                                                </Typography>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        <Box className='delete_dialog_block--button_box'>
                            <StyledCancelButton variant="outlined" onClick={handleClose}>Cancel</StyledCancelButton>
                            <StyledDeletePrimaryButton variant="contained"
                                                       onClick={deleteUnassignedNumbers}>Delete</StyledDeletePrimaryButton>
                        </Box>
                    </Box>
                }

                {
                    // true &&
                    response.isError && !someNumbersAreAssigned &&
                    <Box className='delete_dialog_block'>
                        <SvgIcon className='delete_dialog_block--icon'/>
                        <Box className='delete_dialog_block--text-block-error'>
                            <Typography className='delete_dialog_block--title'>Deletion Error</Typography>
                            <Typography className='delete_dialog_block--paragraph'>
                                We ran into an issue deleting the phone number(s).
                            </Typography>
                        </Box>
                        <Box className='delete_dialog_block--support'>
                            <Typography className='delete_dialog_block--support-paragraph'>
                                Please contact BluIP Support at:
                            </Typography>
                            <Box className='delete_dialog_block--support-contact'>
                                <SvgIcon className='delete_dialog_block--phone'/>
                                <Link className='delete_dialog_block--support-span' target='_blank'
                                      href="phone:+1 866-443-6494">866-443-6494</Link>
                                <SvgIcon className='delete_dialog_block--email'/>
                                <Link className='delete_dialog_block--support-span' target="_blank"
                                      href="mailto:support@bluip.com">support@bluip.com</Link>
                            </Box>
                        </Box>
                        <StyledMediumPrimaryButton variant="outlined"
                                                   onClick={handleClose}>OK</StyledMediumPrimaryButton>
                    </Box>
                }

                {
                    // true &&
                    response.isSuccess &&
                    <Box className='delete_dialog_block'>
                        <SvgIcon className='delete_dialog_block--icon-inprogress'/>
                        <Box className='delete_dialog_block--text-block-success'>
                            <Typography className='delete_dialog_block--title'>Deletion In Progress</Typography>
                            <Typography className='delete_dialog_block--paragraph'>
                                The phone number(s) has been successfully submitted for deletion. Once the
                                phone number(s) has been completely deleted, they will disappear from your portal!
                            </Typography>
                        </Box>
                        <Box className='delete_dialog_block--support'>
                            <Typography className='delete_dialog_block--support-paragraph'>
                                Please contact BluIP Support at:
                            </Typography>
                            <Box className='delete_dialog_block--support-contact'>
                                <SvgIcon className='delete_dialog_block--phone'/>
                                <Link className='delete_dialog_block--support-span' target='_blank'
                                      href="phone:+1 866-443-6494">866-443-6494</Link>
                                <SvgIcon className='delete_dialog_block--email'/>
                                <Link className='delete_dialog_block--support-span' target="_blank"
                                      href="mailto:support@bluip.com">support@bluip.com</Link>
                            </Box>
                        </Box>
                        <StyledMediumPrimaryButton variant="outlined"
                                                   onClick={handleClose}>Ok</StyledMediumPrimaryButton>
                    </Box>
                }
                {
                    // true &&
                    response.isLoading &&
                    <Box className='loading_block'>
                        <SvgIcon className='loading_block--loader'/>
                    </Box>
                }
            </StyledDeleteDialogContent>
        </StyledDeleteDialog>
    )
}

export default DeleteNumbers

var mockAssignedNumbers =
    ["437-782-8460",
        "437-782-8461",
        "437-782-8462",
        "437-782-8463",
        "437-782-8464",
        "437-782-8465",
        "437-782-8478",
        "437-782-8489",
        "437-782-8410",
        "437-782-8412",
        "437-782-8419",
        "437-782-8420",
    ]
