import React from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";

function LoginInProgress() {
    return (
        <Box className="login_progress_block">
            <Box className="login_progress_block--waiting"/>
            <Box className="login_progress_block--loader"/>
            <Typography className="login_progress_block--paragraph">We are now preparing your portal
                experience.</Typography>
            <Typography className="login_progress_block--paragraph">Please hang on!</Typography>
        </Box>
    )
}

export default LoginInProgress;
