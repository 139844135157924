import React from 'react'
const { REACT_APP_LOGIN_REDIRECT_URI} = process.env;
import { Box, Button, Typography, Paper, Link } from '@mui/material';
import { useMsal } from "@azure/msal-react";

function AccessDenied() {
  const {instance, accounts} = useMsal();

  const handleTryAgain = () => {
    localStorage.removeItem("globalAdminInfo");
    localStorage.removeItem("logisenseInfo");
    instance.logout({
      account: accounts[0],
      postLogoutRedirectUri: `${REACT_APP_LOGIN_REDIRECT_URI}/login`,
    });
  }

  return (
    <Box className="access_denied">
      <Paper className="access_denied--block">
        <Box className="access_denied--alert"/>
        <Typography className="access_denied--heading">
          Access Denied!
        </Typography>
        <Typography className="access_denied--paragraph">
          The user account you entered is not associated with a valid BluIp Customer account.  <br/>
          Please contact BluIp Customer Support at (866) 443-6494 or 
          <Link size="small" className="access_denied--link" target="_blank"
                                  id="footer_link_1"
                                  href="https://www.bluip.com/company/legal/privacy-policy">support@bluip.com</Link>
          to become a BluIp customer. Otherwise, please click on the Try Again button and enter <br/>
          your Microsoft Credentials associated with a valid BluIp customer account.  <br/>
        </Typography>
        <Button
            className="access_denied--button"
          onClick={handleTryAgain}
        >
          Try Again
        </Button>
      </Paper>
    </Box>
  )
}

export default AccessDenied;
