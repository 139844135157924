import {useEffect} from "react"
import {Box} from "@mui/material";
import {useOutletContext, useNavigate, useLocation} from "react-router-dom";
import {
    StyledLayoutCircularProgress
} from "../styles/mui_styles";
import {useGetLeadByTenantIdMutation} from '../services/requests';
import AddNumbers from "./number_management/add_numbers/AddNumbers";


function ActiveLead() {
    const context = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [leadDataRequest, leadDataResponse] = useGetLeadByTenantIdMutation();

    useEffect(() => {
        leadDataRequest({tenantId: context.tenantId, sync: false})
    }, [])

    useEffect(() => {
        if (leadDataResponse.data) {
            const leadData = leadDataResponse.data;
            if (leadData.status === "lead_not_processed") {
                return;
            } else if (leadData.status === "lead_processed") {
                navigate('../lead_already_processed')
            } else {
                navigate('../not_found')
            }
        } else if (leadDataResponse.error) {
            navigate('../not_found')
        }
    }, [leadDataResponse])

    return (
        <>
        {location?.state?.status == "lead_not_processed" || leadDataResponse?.data?.status == "lead_not_processed" ?
            <Box className="step_block_container">
                <Box className="step_block">
                    <AddNumbers
                        tenantId={context.tenantId}
                        poc={true}
                        contactDetails={leadDataResponse.data?.lead_data?.contactDetails}
                    />
                </Box>
            </Box> :
            <Box sx={{display: 'flex'}}>
                <StyledLayoutCircularProgress/>
            </Box>
        }
        </>
    );
}

export default ActiveLead;
