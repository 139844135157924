import React, {useState, useEffect} from "react";
import {Typography, Box, SvgIcon} from "@mui/material";
import {
    StyledMobileStepper,
    StyledMediumPrimaryButton,
    StyledMediumSecondaryButton,
    StyledWhiteCircularProgress,
    StyledModalLink
} from "../../../styles/mui_styles";
import {
    useAddPhoneNumbersMutation,
    useStartPOCSetupMutation,
    useGetTaskStatusMutation
} from "../../../services/requests";
import ChooseNumbers from "./ChooseNumbers";
import ChooseNumberType from "./ChooseNumberType";
import ConfirmNumberSelection from "./ConfirmNumberSelection";
import ProvideContactInfo from "../../poc/ProvideContactInfo";
import {DEFAULT_COUNTRY} from "../../../constants/constants";
import useValidateCustomerData from "../../custom_hooks/useValidateCustomerData";
import {countries_and_states} from "../../../assets/countries_and_states";

function AddNumbers({setOpenDialog, tenantId, refetchTasks, poc, contactDetails, username}) {
    const numberOfSteps = poc ? 4 : 3;
    const [activeStep, setActiveStep] = useState(1);

    const [availableDids, setAvailableDids] = useState([])
    const [selectedDids, setSelectedDids] = useState([]);
    const [customerInfo, setCustomerInfo] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: DEFAULT_COUNTRY
    })
    const {validateCustomerData, validationInprogress, setFieldValidity, fieldValidity} = useValidateCustomerData();

    const [addPhoneNumbersRequest, addPhoneNumbersResponse] = useAddPhoneNumbersMutation();
    const [startPOCSetupRequest, startPOCSetupResponse] = useStartPOCSetupMutation();
    const [getTaskStatusRequest, getTaskStatusResponse] = useGetTaskStatusMutation();

    useEffect(() => {
        if (contactDetails) {
            const fullName = contactDetails.fullName.replace(/([^\w! -]+)/g, "");
            const firstName = fullName.split(" ")[0].slice(0, 29)
            const lastName = fullName.split(" ").slice(1).join(" ").slice(0, 29);
            let phoneNumber = contactDetails.phoneNumber?.replace(/\D/g, "") || "";
            if (phoneNumber[0] == "1" && phoneNumber.length > 10) phoneNumber = phoneNumber.slice(1, 11);
            setCustomerInfo(prev => ({
                ...prev,
                firstName: firstName,
                lastName: lastName,
                emailAddress: contactDetails.email,
                phoneNumber: phoneNumber
            }))
        }
    }, [contactDetails])

    const handleNext = async () => {
        if (poc && activeStep === 3) {
            const isValid = await validateCustomerData(customerInfo);
            if (isValid) {
                setActiveStep(prev => prev + 1);
            }
        } else if (activeStep == numberOfSteps) {
            handleConfirm();
        } else {
            setActiveStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 1 && !poc) {
            setOpenDialog(false);
        } else {
            setActiveStep(prev => prev - 1);
        }
    };

    const handleConfirm = async () => {
        const phoneNumbers = selectedDids.map(item => {
            return {
                number: item.number.toString(),
                usage: item.resource ? "FirstPartyAppAssignment" : "CallingUserAssignment",
                acquiredOn: new Date().toISOString()
            }
        });
        if (poc) {
            const customerInfoCopy = JSON.parse(JSON.stringify(customerInfo));
            customerInfoCopy.state = countries_and_states[customerInfo.country].states.find(state => state.abbreviation === customerInfo.state);
            const data = {phoneNumbers, tenantId, customerInfo: customerInfoCopy};
            await startPOCSetupRequest(data);
        } else {
            const resp = await addPhoneNumbersRequest({phoneNumbers, tenantId, username}).unwrap();
            refetchTasks();
        }
    }

    const checkTaskStatus = async (timeoutId, url, taskStatus, id) => {
        clearTimeout(timeoutId);
        if (taskStatus === "Pending" || taskStatus === "Running") {
            timeoutId = setTimeout(async () => {
                const resp = await getTaskStatusRequest(url).unwrap();
                checkTaskStatus(timeoutId, url, resp.runtimeStatus, id)
            }, 5000)
        } else {
            setTaskResults(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                prevCopy.push({
                    id: id,
                    status: taskStatus,
                    refetched: false,
                    didQuantity: selectedDids.length,
                    action: "ADD_NUMBERS"
                });
                return prevCopy;
            })
        }
    }

    const nextIsDisabled = () => {
        if (activeStep == 1 && !selectedDids.length) return true;
        const customerInfoNotFilledOut = customerInfo && Object.entries(customerInfo).find(field => field[0] !== "address2" && !field[1]);
        if (poc && activeStep == 3 && (customerInfoNotFilledOut || validationInprogress)) return true;
        if (addPhoneNumbersResponse.isLoading) return true;
        return false;
    }

    const responseStatus = () => {
        if (poc) {
            if (startPOCSetupResponse.isUninitialized) return "isUninitialized";
            if (startPOCSetupResponse.isLoading) return "isLoading";
            if (startPOCSetupResponse.isSuccess) return "isSuccess";
            if (startPOCSetupResponse.isError) return "isError";
        } else {
            if (addPhoneNumbersResponse.isUninitialized) return "isUninitialized";
            if (addPhoneNumbersResponse.isLoading) return "isLoading";
            if (addPhoneNumbersResponse.isSuccess) return "isSuccess";
            if (addPhoneNumbersResponse.isError) return "isError";
        }
    }

    const handleDone = () => {
        if (poc) {
            window.location.href = "https://www.bluip.com/microsoft-operator-connect";
        } else {
            setOpenDialog(false);
        }
    }

    return (
        <>
            <Box className="flex_justify">
                <Box>
                    {
                        poc ?
                            <Typography className="dialog_title">Get started with Operator Connect</Typography> :
                            <Typography className="dialog_title">Add New Phone Numbers</Typography>
                    }
                    <Box className="stepper_block">
                        {responseStatus() == "isSuccess" ?
                            <Typography className="stepper_block--success">Success!</Typography> :
                            responseStatus() == "isError" ?
                                <Typography className="stepper_block--error">Error!</Typography> :
                                <>
                                    <Typography className="stepper_block--text_active"
                                                fontSize={10}>Step {activeStep}</Typography>
                                    <Typography className="stepper_block--text"
                                                fontSize={10}>&nbsp;of {numberOfSteps}</Typography>
                                </>
                        }
                    </Box>

                <StyledMobileStepper
                    variant="progress"
                    steps={numberOfSteps + 1}
                    position="static"
                    activeStep={activeStep}
                    style={{padding: 0, marginBottom: 10}}
                />
                </Box>
                {activeStep === 1 &&
                <ChooseNumbers
                    availableDids={availableDids}
                    setAvailableDids={setAvailableDids}
                    selectedDids={selectedDids}
                    setSelectedDids={setSelectedDids}
                    poc={poc}
                />
                }
                {activeStep === 2 &&
                <ChooseNumberType
                    selectedDids={selectedDids}
                    setSelectedDids={setSelectedDids}
                />
                }
                {activeStep === 3 && poc &&
                <ProvideContactInfo
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    fieldValidity={fieldValidity}
                    setFieldValidity={setFieldValidity}
                />
                }
                {activeStep === numberOfSteps &&
                <Box className={`step3_block ${responseStatus() == "isUninitialized" ? "leftAlign" : "center"}`}>
                    {
                        // false ?
                        responseStatus() == "isUninitialized" ?
                            <ConfirmNumberSelection
                                userNumbersQuantity={selectedDids.filter(did => !did.resource).length}
                                resourceNumbersQuantity={selectedDids.filter(did => did.resource).length}
                                poc={poc}
                            />
                            :
                            // true ?
                            responseStatus() == "isLoading" ?
                                <SvgIcon className="loader"/>
                                :
                                // true ?
                                responseStatus() == "isSuccess" ?
                                    <Box className="modal_box--congrates">
                                        <Box component="svg" className="delete_dialog_block--icon-inprogress"/>
                                        <Typography component="h2" className="modal_box--setup_title">
                                            { poc ? <span> Congratulations!  <br/>
                                                Setup is now in progress </span> :
                                                <span>Add New Phone Numbers In Progress!</span>
                                            }
                                        </Typography>
                                        <Typography component="p" className="modal_box--setup_span" mb={3}>
                                        {
                                            poc ? 
                                            "You will receive an email notification as soon as the setup has been completed (typically a few minutes)." :
                                            "We have received your request to add new phone numbers. You will receive an email notification as soon as your new phone numbers are available for use."
                                        }
                                        </Typography>
                                        {poc &&
                                        <Typography component="p" className="modal_box--setup_span">
                                            In the meantime, you can learn more about Operator Connect by {" "}
                                            <StyledModalLink underline="hover" target="_blank"
                                                             href="https://www.bluip.com/microsoft-operator-connect">
                                                visiting our website. {" "}
                                            </StyledModalLink>
                                            If you have any questions or concerns please {" "}
                                            <StyledModalLink underline="hover" target="_blank"
                                                             href="mailto:sales@bluip.com">
                                                contact us.
                                            </StyledModalLink>
                                        </Typography>
                                        }
                                    </Box>
                                    :
                                    // true ?
                                    responseStatus() == "isError" ?
                                        <Box className="modal_box--error">
                                            <Box component="svg" className="modal_box--error_img"/>
                                            {
                                                poc ?
                                                    <Box>
                                                        <Typography component="h2" className="modal_box--setup_title">
                                                            Oops! We ran into an issue <br/> setting up your phone
                                                            numbers.
                                                        </Typography>
                                                        <Typography component="p" className="modal_box--setup_span">
                                                            Our support team will be contacting you shortly.
                                                        </Typography>
                                                    </Box>
                                                    :
                                                    <Typography component="div">Something went wrong.</Typography>
                                            }
                                        </Box>
                                        : null
                    }
                </Box>
                }
            </Box>
            <Box className="button_block--number">
                {
                    // false &&
                    responseStatus() == "isUninitialized" &&
                    <>
                        {((poc && activeStep > 1) || !poc) &&
                        <StyledMediumSecondaryButton size="small" onClick={handleBack} variant="outlined">
                            {activeStep === 1 ? "Cancel" : "Back"}
                        </StyledMediumSecondaryButton>}
                        <StyledMediumPrimaryButton size="small" onClick={handleNext} variant="contained"
                                                   disabled={nextIsDisabled()}
                        >
                            {
                                validationInprogress ? <StyledWhiteCircularProgress/> :
                                    activeStep === numberOfSteps ? "Confirm" :
                                        "Next"
                            }
                        </StyledMediumPrimaryButton>
                    </>
                }
                {
                    // true &&
                    (responseStatus() == "isSuccess" || responseStatus() == "isError") &&
                    <StyledMediumPrimaryButton size="small" onClick={handleDone} variant="outlined">
                        OK
                    </StyledMediumPrimaryButton>
                }
            </Box>
        </>
    )
}

export default AddNumbers;


var mockNumbers = {
    "tenantId": "913b25a8-1188-4d04-aaf6-e7661138f463",
    "phoneNumbers": [
        {"number": "7323123143", "usage": "FirstPartyAppAssignment"}
    ]
}
