import {useEffect, useState} from "react";
import {useGetCustomerNumbersQuery, useGetUserTasksQuery, useGetTaskStatusMutation} from '../../services/requests';

function useGetCustomerNumbers({tenantId, username}) {
    
    const {data: numbers, isFetching: numbersIsFetching, isLoading: numbersIsLoading} = useGetCustomerNumbersQuery({tenantId});
    const {data: userTasks, isFetching: userTasksIsFetching, isLoading: userTasksIsLoading, refetch: refetchTasks } = useGetUserTasksQuery({username});
    const [getTaskStatusRequest] = useGetTaskStatusMutation();

    const [customerNumbers, setCustomerNumbers] = useState();
    const [pendingNumbersQty, setPendingNumbersQty] = useState(0);
    const [taskResults, setTaskResults] = useState();
    const [completedTasks, setCompletedTasks] = useState([]);

    useEffect(() => {
        if (userTasks) {
            const tasks = JSON.parse(JSON.stringify(userTasks));
            setTaskResults(tasks);
            const completedTasks = tasks.filter(task => task.customStatus);
            setCompletedTasks(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                return [...prevCopy, ...completedTasks]
            });
            const pendingTasks = userTasks.filter(task => !task.customStatus);
            const timeoutIds = new Array(pendingTasks.length).fill(null);
            const taskPromises = pendingTasks.map((item, index) => checkTaskStatus(timeoutIds[index], item));
            Promise.all(taskPromises);
        }
    }, [userTasks])


    const checkTaskStatus = async(timeoutId, task) => {
        clearTimeout(timeoutId);
        if (!task.customStatus) {
            // schedule another request to check if the task is done 
            timeoutId = setTimeout(async() => {
                const resp = await getTaskStatusRequest(task.instanceId).unwrap();
                checkTaskStatus(timeoutId, resp)
            }, 5000)
        } else {
            // display notification and update numbers table
            const completedTask = JSON.parse(JSON.stringify(task))
            setTaskResults(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                const taskIndex = prevCopy.findIndex(item => item.instanceId == task.instanceId);
                prevCopy[taskIndex] = completedTask;
                return prevCopy;
            })
            setCompletedTasks(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                prevCopy.push(completedTask);
                return prevCopy;
            })
        }
    }

    useEffect(() => {
        if (numbers && taskResults) {
            let numbersCopy = JSON.parse(JSON.stringify(numbers));
            if (taskResults.length) {
                let pendingNumbersQty = 0;
                const additionTasks = taskResults.filter(task => task.name === "AddNumbersOrchestrator").sort((a, b) => -a.createdTime.localeCompare(b.createdTime));
                let  allAddedNumbers = [];
                if (additionTasks.length) {
                    additionTasks.forEach(task => {
                        task.input.phoneNumbers.forEach(item => {
                            const inAudioCodes = numbers.find(elem => elem.telephoneNumber === `+1${item.number}`);
                            console.log("task.customStatus?.result", task.customStatus?.result)
                            console.log("inAudioCodes", inAudioCodes)
                            console.log("!inAudioCodes && !task.customStatus?.result === 'error'", !inAudioCodes && !task.customStatus?.result === "error")
                            if (!inAudioCodes && task.customStatus?.result !== "error") {
                                const num = {
                                    telephoneNumber: `+1${item.number}`,
                                    isoCountryCode: "",
                                    acquiredOn: item.acquiredOn,
                                    numberType: item.usage.includes("FirstPartyAppAssignment") ? "Resource" : "User",
                                    pendingAddition: !task.customStatus
                                }
                                console.log("num", num)
                                allAddedNumbers.push(num);
                                if (num.pendingAddition) {
                                    pendingNumbersQty++;
                                }
                            }
                        });
                    });
                }

                const allNumbers = [...allAddedNumbers, ...numbersCopy];

                const deletionTasks = taskResults.filter(task => task.name === "DeleteNumbersOrchestrator");
                if (deletionTasks.length) {
                    deletionTasks.forEach(task => {
                        task.input.phoneNumbers.forEach(item => {
                            const numberIndex = allNumbers.findIndex(elem => elem.telephoneNumber === `+1${item}`);
                            if (numberIndex > -1) {
                                if (!task.customStatus) {
                                    allNumbers[numberIndex].pendingDeletion = true;
                                    pendingNumbersQty++;
                                } else if (task.customStatus?.result !== "error"){
                                    allNumbers.splice(numberIndex, 1);
                                }
                            }
                        })
                    })
                }

                setCustomerNumbers(allNumbers);
                setPendingNumbersQty(pendingNumbersQty);

            } else {
                setCustomerNumbers(numbersCopy);
            }
        }
    
    }, [numbers, taskResults])
    
    
    return {
        customerNumbers,
        isLoading: numbersIsFetching || numbersIsLoading || userTasksIsFetching || userTasksIsLoading,
        pendingNumbersQty,
        completedTasks,
        setCompletedTasks,
        refetchTasks
    };
}

export default useGetCustomerNumbers;