import { useCallback, useRef } from "react";
import { Typography, Box, ListItem, ListItemText, Checkbox, List, Collapse, SvgIcon } from "@mui/material";
import {
    StyledListItemIcon,
    StyledHoverListItemButton
} from "../../../styles/mui_styles";
import { TransitionGroup } from 'react-transition-group';
import MemoizedListItem from "./MemoizedListItem";

const ChooseNumberType = ({selectedDids, setSelectedDids}) => {

    const lastDIDRef = useRef(null);

    const cachedHandleResourceSelection = useCallback((value) => {
        setSelectedDids((prev) => {
            const prevCopy = JSON.parse((JSON.stringify(prev)));
            const number = prevCopy.find(item => item.number == value.number);
            number.resource = !number.resource;
            return prevCopy;
        })
        if (!value.resource) {
            setTimeout(() => {
                lastDIDRef.current.scrollIntoView({block: "end", behavior: 'smooth'});
            }, 600)
        }
    }, [])

  return (
    <Box className="step_content">
    <Box className="form_title">
        <Typography className="form_title--step-2">A Microsoft voice resource phone number is a phone number that can be assigned to a resource account in Microsoft Teams.
        Resource accounts are required for all auto attendants and call queues in Microsoft Teams.</Typography>
    </Box>
    <Box className="number_block">
        <Box className="number_block--available">
            <Typography className="number_block--title">Selected Phone Numbers {selectedDids.length}</Typography>
            <List className="number_block--list_block_claimed">
                {
                    selectedDids?.map(item => {
                        return <MemoizedListItem {...item} key={item.number} selectResource={true} handleDIDSelection={cachedHandleResourceSelection}/>
                    })
                }
            </List>
        </Box>
        <Box className="number_block--claimed">
            <Typography className="number_block--title">Assigned as a Resource {selectedDids.filter(item => item.resource).length}</Typography>
            <TransitionGroup className="number_block--list_block_claimed" >
                {selectedDids.filter(item => item.resource)?.map((item, index) => {
                    return <Collapse timeout={600} key={item.number} ref={index === selectedDids.length - 1 ? lastDIDRef : null}>
                    <ListItem component="div" disablePadding>
                        <StyledHoverListItemButton role={undefined} onClick={() => cachedHandleResourceSelection(item)} dense>
                            <StyledListItemIcon>
                                <Checkbox
                                    checkedIcon={<SvgIcon className='checked_icon'/>}
                                    icon={<SvgIcon className='unchecked_icon'/>}
                                    sx={{paddingTop: 0, paddingBottom: 0}}
                                    size="small"
                                    edge="start"
                                    checked={true}
                                    disableRipple
                                />
                            </StyledListItemIcon>
                            <ListItemText className="number_block--num_text" primary={item.number} />
                        </StyledHoverListItemButton>
                    </ListItem>
                    </Collapse>
                })}
            </TransitionGroup>
        </Box>
    </Box>
    </Box>
  )
}

export default ChooseNumberType;
