import React, {useState} from 'react'
import {List, ListItem, ListItemButton, Checkbox, ListItemText, Box, Stack, Typography, TextField, Button, FormControlLabel, Radio, SvgIcon} from '@mui/material';
import {
    StyledRadio,
    StyledListItemIcon,
    StyledChip,
    StyledButton,
    StyledFormControlLabel
} from "../../styles/mui_styles";

function AreaCodePicker({appliedAreaCodes, setAppliedAreaCodes, searchAreaCode, setSearchAreaCode, appliedAreaCodeRadioGroupValue, setAppliedAreaCodeRadioGroupValue, handleFiltersStateChange }) {

    const [areaCodeOptions, setAreaCodeOptions] = useState(appliedAreaCodes)
    const [areaCodeRadioGroupValue, setAreaCodeRadioGroupValue] = useState(appliedAreaCodeRadioGroupValue);

    const handleToggle = (index, item) => {
        setSearchAreaCode("");
        setAreaCodeOptions(prev => {
            const prevCopy = prev.map(item => {
                const newItem = {...item};
                newItem.visible = true;
                return newItem;
            });
            prevCopy[index].checked = !prevCopy[index].checked;
            return prevCopy;
        })
        // if (!item.checked) {
        //     setAreaCodeRadioGroupValue("")
        // } else if (areaCodeOptions.filter(item => item.checked).length === 1) {
        //    setAreaCodeRadioGroupValue("All")
        // }
    };

    const handleSearch = (value) => {
        setSearchAreaCode(value);
        setAreaCodeOptions(prev => {
            console.log(prev)
            const data = prev.map(item => {
                const visible = item.value.includes(value);
                const newItem = {...item};
                newItem.visible = visible;
                return newItem;
            })
            return data;
          })
    }

    const handleApply = () => {
        const filterValue = areaCodeOptions.filter(item => item.checked)
        console.log(filterValue)
        setAppliedAreaCodes(areaCodeOptions);
        setAppliedAreaCodeRadioGroupValue("");
        handleFiltersStateChange("telephoneNumber", filterValue.map(item => item.value));
    }

    const handleClear = () => {
        setSearchAreaCode("");
        setAreaCodeOptions(prev => {
            const prevCopy = prev.map(item => {
                const newItem = {...item};
                newItem.checked = false;
                newItem.visible = true;
                return newItem;
            });
            return prevCopy;
        })
    }

    const handleAllSelection = () => {
        setAreaCodeRadioGroupValue("All");
        setAppliedAreaCodeRadioGroupValue("All");
        setAppliedAreaCodes(prev => {
            const prevCopy = prev.map(item => {
                const newItem = {...item};
                newItem.checked = false;
                return newItem;
            });
            return prevCopy;
        })
        handleClear();
        handleFiltersStateChange("telephoneNumber", "All");
    }

  return (
        <Box>
            <Box className='datapicker_block'>
                <FormControlLabel
                    onChange={handleAllSelection}
                    value={areaCodeRadioGroupValue}
                    control={<StyledRadio size="small" />}
                    label={<span style={{ fontSize: '14px' }}>All</span>}
                    checked={areaCodeRadioGroupValue=== "All"}
                />
            </Box>
        <Box className='npa_search_block'>
        <Box className='search_field'>
            <Typography className='npa_label'>Area Code</Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {
                areaCodeOptions.map((item, index) => {
                    return item.checked && <StyledChip label={item.value} key={item.value} size='small' onDelete={() => handleToggle(index, item)} />
                })
            }
            <TextField
                variant='standard'
                size="small"
                sx={{width: 50}}
                placeholder='Search'
                InputProps={{ disableUnderline: true, style: {fontSize: 14,}}}
                inputProps={{style: {paddingTop: 4}}}
                value={searchAreaCode}
                onChange={(e) => handleSearch(e.target.value)}
            />
         </Stack>
        </Box>
       <List className='npa_list'  sx={{ width: '100%',  height: 200, overflowX: 'auto' }}>
        {areaCodeOptions && areaCodeOptions.map((item, index) => {
            const labelId = `checkbox-list-label-${item.value}`;
            return item.visible &&  (
            <ListItem key={item.value} disablePadding >
                <ListItemButton role={undefined} onClick={() => handleToggle(index, item)} dense style={{height: 30}}>
                <StyledListItemIcon>
                    <Checkbox
                    edge="start"
                    checkedIcon={<SvgIcon  className='checked_icon'/>}
                    icon= {<SvgIcon  className='unchecked_icon'/>}
                    checked={item.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    size='small'
                    />
                </StyledListItemIcon>
                <ListItemText id={labelId} primary={item.value} />
                </ListItemButton>
            </ListItem>
            );
            })
        }
        </List>
        <Box className='button_block'>
            <StyledButton className='secondary' size='small' onClick={handleClear} disabled={!areaCodeOptions.find(item=> item.checked)}>Clear</StyledButton>
            <StyledButton className='primary' onClick={handleApply} disabled={!areaCodeOptions.find(item=> item.checked)}>Apply</StyledButton>
        </Box>
        </Box>
    </Box>
  )
}

export default AreaCodePicker;
